import axiosLib from "../lib/axiosLib";

export const createProfile = values => axiosLib.post("profile/", values);
export const updateProfile = (uuid, values) => axiosLib.put(`profile/${uuid}/`, values);
export const getProfiles = ({ university, college, course, year, state, outcome, limit = '', offset = '' }) =>
  axiosLib.get(
    `profile/?&university__uuid=${university}&college__uuid=${college}&course__uuid=${course}&year=${year}&state=${state}&application_outcome=${outcome}&limit=${limit}&offset=${offset}`
  );
export const getProfileByUuid = uuid => axiosLib.get(`profile/${uuid}/`);         // e.g. /profile/a7a63650-7e5d-40f9-875c-e27ecde9c607
export const getProfile = id => axiosLib.get(`profile_id/${id}/`);  // e.g. /profile_id/32
export const getMyProfile = () => axiosLib.get("profile/my_profile/");
export const getUniversities = () => axiosLib.get("university/");
export const signUp = values => axiosLib.post("auth/signup/", values);
export const signIn = values => axiosLib.post("auth/login/", values);
export const logOut = () => axiosLib.get("auth/logout/");
export const currentUser = () => axiosLib.get("auth/current_user/");
export const getToken = values => axiosLib.post("auth/token/", values);
export const approveProfile = (uuid, message) => axiosLib.post(`profile/${uuid}/approve/`, { message });
export const rejectProfile = (uuid, message) => axiosLib.post(`profile/${uuid}/dispprove/`, { message });
export const contactUs = values => axiosLib.post("contact/", values);
export const contactUser = (uuid, values) => axiosLib.post(`profile/${uuid}/contact_me/`, values);
export const getNotificationStrip = () => axiosLib.get(`notification_strip/`);

export const setDefaultHeader = (
  name: string,
  value: string | number | null
) => {
  if (value) {
    axiosLib.defaults.headers.common[name] = value; // eslint-disable-line
  } else {
    delete axiosLib.defaults.headers.common[name];
  }
};
