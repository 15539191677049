import React from 'react';
import images from "../images/images";
import isMobileView from "../utils/isMobileView";

const styles = {
    container: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 25px"
    },
    containerMobile: {
        display: "flex",
        flexDirection: "column",
        padding: "20px 16px"
    },
    text: {
        letterSpacing: 0,
        color: "#707070",
        opacity: 1,
        display: "block",
        textAlign: "justify",
        fontSize: "0.75rem"
    },
    logo: {
        alignItems: "center",
        marginLeft: 60,
        cursor: "pointer"
    },
    logoMobile: {
        alignItems: "right",
        alignSelf: "flex-end",
        marginBottom: 8,
        cursor: "pointer"
    }
}

const AboutBox = ({handleClose}) => (
    <div style={isMobileView() ? styles.containerMobile : styles.container}>
        <img src={images.cancel} style={isMobileView() ? styles.logoMobile : styles.logo} alt="Cancel" onClick={() => handleClose()} />
        <label style={styles.text}>Oxbridge Admissions was founded in 2006 to level the playing field for applying to Oxford and Cambridge (aka Oxbridge). We want your chances of admission to be based on your academic merit, not on whether your parents can afford an expensive private school or specialized tutors that coach you for the interviews. We hope the profiles, filled out by recent and former Oxbridge applicants, can help you figure out whether to apply, which university and college to apply for, how to write a good application, and what to expect in Oxbridge interviews. To learn more about our mission, <a href="https://oxbridgeadmissions.com/about">click here</a>, and to share your application experiences, <a href="https://oxbridgeadmissions.com/signin">start here</a>.</label>
    </div>
) 

export default AboutBox;