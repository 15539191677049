const getOutcomeOptions = () => [
  { label: "Still waiting / will update", key: "W" },
  { label: "Offer made", key: "OM" },
  { label: "Pooled, offer made", key: "POF" },
  { label: "Pooled, rejected", key: "PR" },
  { label: "Open offer made", key: "OOM" },
  { label: "Rejected", key: "R" }
];

export default getOutcomeOptions;
