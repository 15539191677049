const getSchoolLeavingExamsOptions = () => [
  {
    key: 'AL',
    label: 'A-levels'
  },
  {
    key: 'IB',
    label: 'International Baccalaureate'
  },
  {
    key: 'SH',
    label: 'Scottish Highers/Advanced Highers'
  },
  {
    key: 'AAL',
    label: '"Asian" A-levels'
  },
  {
    key: 'O',
    label: 'Other'
  },
];

export default getSchoolLeavingExamsOptions;
