import React, { Component } from "react";
import {Helmet} from "react-helmet";

// external libs
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from "formik";

// components
import Content from "../components/Content";
import Error from "../components/Error";

// api
import { signIn, getToken, setDefaultHeader, getMyProfile } from "../api/api";

const styles = {
  sendButton: {
    backgroundColor: "#00326b",
    //marginRight: "10px",
    borderRadius: "20px",
    padding: "4px 17px 5px",
    textTransform: "none",
    eight: "45px",
    width: "240px"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold"
  },
  textInputContainer: {
    maxWidth: 300,
    margin: 'auto',
  },
  textInput: {
    margin: "0 0 8px 0",
    width: "100%",
    maxWidth: "300px"
  },
  error: {
    color: 'red',
  }
};

const validate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};


export function verifyLogin(loginCode) {
  localStorage.clear()
  return getToken({ token: loginCode })
    .then(response => {
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("isAdmin", response.data.isAdmin);
      setDefaultHeader("Authorization", "Token " + response.data.token);
    })
    .then(getMyProfile)
    .then(setMyProfile)
}

export function setMyProfile(response) {
  if (response.data.uuid) {
    // caution: these are also used for boolean checks -> !!profileState etc.
    localStorage.setItem("profile", response.data.id);
    localStorage.setItem("profileId", response.data.profileId);
    localStorage.setItem("profileState", response.data.state);
  } else {
    localStorage.removeItem("profile");
    localStorage.removeItem("profileId");
    localStorage.removeItem("profileState");
  }
  return response
}

class AboutScreen extends Component {
  state = {
    loginCode: null,
    formSent: false,
    errors: ""
  };

  componentDidMount() {
    var url = new URL(window.location.href);
    const loginCode = url.searchParams.get("code");

    if (!!loginCode) {
      this.props.history.replace({
        pathname: '/',
      })
    }
  }

  render() {
    return (
      <Content style={{ flexDirection: "column", alignItems: "center", paddingTop: "60px" }}>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title>Oxbridge Admissions - Sign In </title>
          <meta name="description" content="Sign-in and share your Oxford and Cambridge experience with fellow students and applicants. If you’ve recently applied or attended Oxbridge, please consider filling out a profile so that others can learn from your experience." />
          <meta name="keywords" content="Oxbridge, Oxford University, Cambridge University, Oxbridge Admissions, Oxbridge Applications" />
        </Helmet>
        <h1 style={{ marginBottom: "30px" }}>Sign in</h1>
        {!(this.state.loginCode || this.state.formSent) && (
          <Formik
            initialValues={{
              email: ""
            }}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
              signIn({ email: values.email })
                .then(() => this.setState({ formSent: true }))
                .catch(err => {
                  const errors = (err.response && err.response.data.nonFieldErrors) || [];
                  this.setState({ errors: errors.join(" ") });
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                {this.state.errors
                  ? <p style={styles.error} dangerouslySetInnerHTML={{ __html: this.state.errors }}/>
                  : <p>What's your email? We'll email you a link that will sign you in.</p>
                }
                <div style={styles.textInputContainer}>
                  <TextField
                    id="email"
                    label="Your email"
                    value={values.email}
                    onChange={handleChange}
                    variant="outlined"
                    style={styles.textInput}
                  />
                  <Error>
                    <ErrorMessage name="email" component={"div"} />
                  </Error>
                </div>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={styles.sendButton}
                >
                  <span style={styles.buttonText}>Sign in</span>
                </Button>
              </form>
            )}
          </Formik>
        )}
        {this.state.formSent && (
          <p>We have sent you a link to log in. Please check your inbox.</p>
        )}
        <span>Don't have an account? <Link to='signup'>Sign up here</Link></span>
      </Content>
    );
  }
}

export default AboutScreen;
