import React from "react";
import RequireStars from "./RequireStars";

const styles = {
  questionWrapper: {
    marginBottom: "60px",
    textAlign: "left"
  },
  label: {
    marginBottom: "20px",
    marginLeft: "20px",
    maxWidth: "500px"
  }
};

const Question = props => {
  return (
    <div style={styles.questionWrapper}>
      <div style={styles.label}>
        {props.label} {props.required && <RequireStars />}
      </div>
      {props.children}
    </div>
  );
};

export default Question;
