import React from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//utils
import isMobileView from "../utils/isMobileView";

import images from "../images/images";

let desktopStyle = {
  ExpansionPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: "0",
    borderBottom: "solid 1px #0000003d",
    marginBottom: "0"
  },
  ExpansionPanelSummary: {
    width: "100%",
    //maxWidth: "300px",
    textAlign: "left",
    //alignItems: "flex-start",
    paddingLeft: "60px",
    height: "88px",
    display: "flex",
    alignItems: "center"
  },
  ExpansionPanelDetails: {
    display: "block",
    padding: "7px 62px 24px 300px"
  },
  icon: {
    margin: "-3px 15px 0 0",
    minWidth: "23px",
    maxWidth: "23px",
    height: "36px"
  },
  title: {
    fontSize: "18px",
    marginBottom: "0",
    textAlign: "left"
  }
}

let mobileStyle = {
  ExpansionPanel: {
    flexDirection: "column",
    boxShadow: "none"
  },
  ExpansionPanelDetails: {
    padding: "7px 24px 24px",
    flexDirection: "column"
  },
  ExpansionPanelSummary: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  icon: {
    margin: "0 15px 0 0",
    minWidth: "23px",
    maxWidth: "23px",
    height: "36px"
  },
}

const ExpansibleBlock = props => {
  let style = isMobileView() ? mobileStyle : desktopStyle
  return (
    <ExpansionPanel defaultExpanded={props.defaultExpanded} style={style.ExpansionPanel}>

      <ExpansionPanelSummary style={style.ExpansionPanelSummary} expandIcon={<ExpandMoreIcon />}>
        {(props.title && !props.noImage) && <img src={images[props.icon || props.title]} alt="" style={style.icon} />}
        <h2 style={style.title}>{props.title}</h2>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails style={style.ExpansionPanelDetails}>
        {/* bcs 34 for distance in questionary form */}
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExpansibleBlock;
