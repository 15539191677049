import React, { Component } from 'react';
import { getNotificationStrip } from '../api/api'

class NotificationStrip extends Component {

  constructor(props) {
    super(props);
    this.state = {
      content: ''
    }
  }

  componentDidMount() {
    getNotificationStrip().then(result => {
      if (result.data.length > 0){
        this.setState({
          // We want to get just the first object
          content: result.data[0].content
        });
      }
    })
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
  }
}

export default NotificationStrip;
