import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

// material
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// external libs
import { Formik } from "formik";

// components
import ExpansibleBlock from "../components/ExpansibleBlock";
import UserDetailRow from "../components/UserDetailRow";
import ContactForm from "../components/ContactForm";
import Content from "../components/Content";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// api
import {
  getProfile,
  approveProfile,
  rejectProfile,
  contactUser
} from "../api/api";

// utils
import getApplicationTimeName from '../utils/getApplicationTimeName';
import getOutcomeName from "../utils/getOutcomeName";
import getOfferConditionsName from "../utils/getOfferConditionsName";
import getStateName from "../utils/getStateName";
import images from "../images/images";
import isMobileView from "../utils/isMobileView";
import { PATHS } from '../App';
import { sectionTitles } from './Form';

let desktopStyles = {
  headline: {
    padding: "24px 60px", // was 4Opx top?
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  contactLink: {
    textDecoration: "none"
  },
  h2: {
    fontSize: "32px"
  },
  approveButton: {
    backgroundColor: "#35d86a",
    marginRight: "10px",
    borderRadius: "20px",
    padding: "4px 17px 5px",
    height: "32px",
    textTransform: "none"
  },
  rejectButton: {
    backgroundColor: "#ec5151",
    borderRadius: "20px",
    padding: "4px 17px 5px",
    height: "32px",
    textTransform: "none"
  },
  editButton: {
    backgroundColor: 'rgb(9, 55, 105)',
    padding: '4px 17px 5px',
    height: 32,
    maxWidth: 100
  },
  buttonText: {
    color: "white",
    fontWeight: "bold"
  },
  expansionsBoxesWrapper: {
    marginBottom: "40px"
  },
  contactForm: {
    paddingTop: "30px",
    display: "inline-block",
    width: 320,
  },
  profileId: {
    fontSize: "18px",
    fontWeight: "500"
  },
  throttling: {
    marginTop: "100px"
  },
  headerBox: {
    backgroundColor: "#efefef",
    textAlign: "left",
    padding: "30px 60px 42px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  headlineInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  errorMessage: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    height: 18,
  }
};

const mobileStyles = {
  headline: {
    padding: "24px 40px",
    width: "100%"
  },
  headerBox: {
    padding: "30px 24px 42px",
    backgroundColor: "#efefef",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  headlineInner: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  editButton: {
    width: 'unset',
  },
  errorMessage: desktopStyles.errorMessage
}

let styles

const getGenderName = gender => {
  switch (gender) {
    case "F":
      return "Female";
    case "M":
      return "Male";
    case "O":
      return "Other";
    case "p":
      return "Don´t want to say";
    default:
      return "";
  }
};

const isAnswerNotEmpty = answer => {
  if (answer === undefined) return false
  if (answer === null) return false
  if (answer.length === 0) return false
  if (answer && typeof answer === 'string') {
    if (!answer.replace(/[^a-zA-Z0-9_ ]/g, "")) {
      return false
    }
  }
  return true
}

let isAdmin = false;
let userID = null;
let userProfileId = null;
let myProfileId = null;

class UserScreen extends Component {
  state = {
    userProfile: {},
    showThrottling: false,
    showDisproveDialog: false,
    showApproveDialog: false,
    isMessageSent: false,
  };

  componentDidMount() {
    userProfileId = this.props.match.params.profileId
    this.getUserProfile();
    isAdmin = localStorage.getItem("isAdmin") === 'true';
    myProfileId = localStorage.getItem('profileId')
  }

  getUserProfile = () => {
    getProfile(userProfileId)
      .then(response => {
        this.setState({ userProfile: response.data });
        userID = response.data.uuid
      })
      .catch(err => {
        if ((err.response||{}).status === 429) {
          this.setState({ showThrottling: true });
        }
        if ((err.response||{}).status === 404) {
          this.props.history.push('')
        }
      });
  };

  handleSubmitContactForm = (values, setSubmitting, resetForm) => {
    contactUser(userID, values)
      .then(() => {
        resetForm();
        this.setState({ isMessageSent: true })
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  handleApproveProfile = () => {
    this.setState({ showApproveDialog: true });
  };

  handleRejectProfile = () => {
    this.setState({ showDisproveDialog: true });
  };

  onApproveProfile = message => {
    approveProfile(userID, message).then(() => {
      this.getUserProfile();
    });
  };

  onRejectProfile = message => {
    rejectProfile(userID, message).then(() => {
      this.getUserProfile();
    });
  };

  handleCloseDialog = () => {
    this.setState({ showDisproveDialog: false, showApproveDialog: false });
  };

  renderApproveDialog = () => this.renderDialog({
    title: 'Approve profile',
    label: 'Please provide a reason to approve profile',
    handler: this.onApproveProfile,
  })

  renderDisproveDialog = () => this.renderDialog({
    title: 'Reject profile',
    label: 'Please provide a reason to reject profile',
    handler: this.onRejectProfile,
  })

  renderDialog = ({title, label, handler}) => (
    <Dialog
      open={true}
      onClose={this.handleCloseDialog}
      aria-labelledby="form-dialog-title"
      fullWidth={isMobileView()}
    >
      {isMobileView() && <style>{`
        .MuiDialog-paperFullWidth-13 {
          margin: 0;
        }
      `}</style>
      }
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>

      <Formik
        initialValues={{message: ""}}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          this.handleCloseDialog();
          handler(values.message);
        }}
        validate={({message}) =>
          message.length >= 10
            ? ({})
            : ({message:`Type ${10 - message.length} more characters`})
        }
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          errors,
          touched,
        }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText>
                  {label}
                </DialogContentText>
                <TextField
                  id="message"
                  label="Message"
                  multiline
                  rowsMax="10"
                  rows="6"
                  value={values.message}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  style={{ width: '100%' }}
                />
                <p style={styles.errorMessage}>
                  {touched.message && errors.message}
                </p>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialog} color="primary">
                  Cancel
              </Button>
                <Button type="submit" color="primary">
                  Send
              </Button>
              </DialogActions>
            </form>
          )}
      </Formik>
    </Dialog>
  );

  renderThrottling = () => (
    <div style={styles.throttling}>
      You must <Link to={PATHS.signin}>sign in</Link> to see more profiles.
      <br />
      If you don&apos;t have an account you can <Link to={PATHS.signup}>sign up here</Link>.
    </div>
  );

  render() {
    styles = isMobileView() ? mobileStyles : desktopStyles

    const {
      id,
      profileId,
      universityName,
      collegeName,
      oxbridgeReason,
      openApplication,
      collegeApplicationReason,
      courseName,
      year,
      deferredEntry,
      applicationOutcome,
      yearQualification,
      applicationTimeFinalGrades,
      nationality,
      GCSE,
      subjectsAndGrades,
      advancedPapers,
      otherQualifications,
      helpFromSchool,
      waitingGettingDecision,
      offerConditions,
      offerAccepted,
      offerNotAcceptedReason,
      offerConditionsMet,
      offerType,
      preInterviewExams,
      writtenWork,
      interviewExams,
      interviewInGeneral,
      questionsAtInterview,
      clothesAtInterview,
      generalCollegeImpression,
      collegeAccommodation,
      wouldStillApply,
      contactMe,
      highSchoolCountry,
      gender,
      state,
      adviceForApplicants
    } = this.state.userProfile;

    const sectionOne = [
      oxbridgeReason,
      openApplication,
      collegeApplicationReason,
      deferredEntry,
      yearQualification,
      gender,
      nationality,
      highSchoolCountry,
      GCSE,
      subjectsAndGrades,
      advancedPapers,
      otherQualifications,
      helpFromSchool
    ];

    const sectionTwo = [
      preInterviewExams,
      writtenWork,
      interviewExams,
      interviewInGeneral,
      questionsAtInterview,
      clothesAtInterview,
      generalCollegeImpression,
      collegeAccommodation
    ];

    const sectionThree = [
      waitingGettingDecision,
      offerType,
      offerConditions,
      offerAccepted,
      offerNotAcceptedReason,
      offerConditionsMet
    ];

    const sectionFour = [
      wouldStillApply,
      adviceForApplicants
    ];

    const showSection = fields => {
      for(var i = 0; i < fields.length; i++){
          if(fields[i] === "" || fields[i] === null || fields[i] === 0   || (fields[i] && typeof fields[i] === 'string' && !fields[i].replace(/[^a-zA-Z0-9_ ]/g, ""))) {
            if(i === fields.length -1){
              return false;
            }
          } else {
            return true
          }
      }
    }
    return (
      <Content style={{ flexDirection: "column", alignItems: "center" }}>
        {this.state.showDisproveDialog && this.renderDisproveDialog()}
        {this.state.showApproveDialog && this.renderApproveDialog()}
        {this.state.showThrottling
          ? this.renderThrottling()
          : profileId && (
              <>
                <Helmet>
                  <meta name="robots" content="index, follow" />
                  <title>{`Oxbridge Admissions - Profile #${id}`}</title>
                  <meta name="description" content={`Profile #${id} on Oxbridge Admissions, a leading resource for Oxford and Cambridge university applications`} />
                  <meta name="keywords" content="Oxbridge, Oxford University, Cambridge University, Oxbridge Admissions" />
                </Helmet>

                {isAdmin && (
                  <div style={styles.headline}>
                      <div style={styles.headlineInner}>
                        <img
                          src={images.profile}
                          alt={profileId}
                          style={{ marginRight: "9px" }}
                        />
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              fontSize: "15px",
                              color: "#000",
                              fontWeight: "500",
                              textAlign: "left",
                              lineHeight: "10px"
                            }}
                          >
                            {getStateName(state)}
                          </div>
                        </div>
                      </div>

                    {/* red & green buttons */}

                    {state === "P" && isAdmin && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button
                          style={styles.approveButton}
                          onClick={this.handleApproveProfile}
                        >
                          <span style={styles.buttonText}>Approve</span>
                        </Button>

                        <Button
                          style={styles.rejectButton}
                          onClick={this.handleRejectProfile}
                        >
                          <span style={styles.buttonText}>Reject</span>
                        </Button>
                      </div>
                    )}

                  </div>
                )}

                {profileId === myProfileId && (
                  <div style={styles.headline}>
                      <div style={{...styles.headlineInner, width: '100%'}}>
                        <img
                          src={images.profile}
                          alt={profileId}
                          style={{ marginRight: "9px" }}
                        />
                        <div style={{ position: "relative", flex: 1 }}>
                          <div
                            style={{
                              fontSize: "15px",
                              color: "#000",
                              fontWeight: 500,
                              textAlign: "left",
                              lineHeight: "10px"
                            }}
                          >
                            { state === 'A' && 'Publicly visible' }
                            { state === 'P' && 'Pending approval' }
                            { state === 'D' && 'Profile rejected' }
                          </div>
                        </div>
                        <Button
                          style={styles.editButton}
                          onClick={() => this.props.history.push(PATHS.form)}
                        >
                          <span style={styles.buttonText}>{state === 'D' ? 'Resubmit' : 'Edit'}</span>
                        </Button>
                      </div>
                  </div>
                )}

                <div style={styles.expansionsBoxesWrapper}>

                  <div style={styles.headerBox}>
                    <div>
                    <h2 style={{ fontSize: "30px", margin: "0 0 20px 0", color: "#00326b", display: "flex", alignitems: "center" }}>
                    {universityName === "Oxford" ? (
                      <img
                        src={images.oxford}
                        alt={universityName}
                        style={{ marginRight: "9px", marginLeft: "-6px", width: "40px", height: "40px" }}
                      />
                    ) : (
                      <img
                        src={images.cambridge}
                        alt={universityName}
                        style={{ marginRight: "9px", marginLeft: "-6px", width: "40px", height: "40px" }}
                      />
                    )} {universityName}
                    </h2>
                    <div style={{ fontWeight: "500", fontSize: "14px", color: "#4a4a4a", lineHeight: "1.43" }}>{collegeName}</div>
                    <div style={{ fontWeight: "500", fontSize: "14px", color: "#4a4a4a", lineHeight: "1.43" }}>{courseName}</div>
                    {/* <div style={{ fontWeight: "500", fontSize: "14px", color: "#4a4a4a", lineHeight: "1.43" }}>{year}</div> */}
                    <div style={{ fontWeight: "500", fontSize: "14px", color: "#4a4a4a", lineHeight: "1.43" }}>
                      {getOutcomeName(applicationOutcome)}
                    </div>
                    </div>
                    <div style={{  color: "#000", fonrSize: "18px" }}>
                      <div style={styles.profileId}>{profileId}</div>
                    </div>
                  </div>

                {showSection(sectionOne) && <ExpansibleBlock title={sectionTitles[1]} icon="Applicant" defaultExpanded>

                    {isAnswerNotEmpty(openApplication) && (
                      <UserDetailRow
                        question="Did you make an open application?"
                        answer={openApplication ? "Yes" : "No"}
                      />
                    )}
                    {isAnswerNotEmpty(collegeApplicationReason) && (
                      <UserDetailRow
                        question="Why did you choose this college/make an open application?"
                        answer={collegeApplicationReason}
                      />
                    )}
                    {isAnswerNotEmpty(oxbridgeReason) && (
                      <UserDetailRow
                        question="For what reasons did you apply to Oxbridge? How did you
                      decide between Oxford and Cambridge?"
                        answer={oxbridgeReason}
                      />
                    )}
                    {isAnswerNotEmpty(year) && (
                      <UserDetailRow
                        question="Year in (not for!) which you applied?"
                        answer={year}
                      />
                    )}
                    {isAnswerNotEmpty(deferredEntry) && (
                      <UserDetailRow
                        question="Deferred entry"
                        answer={deferredEntry ? "Yes" : "No"}
                      />
                    )}
                    {isAnswerNotEmpty(yearQualification) && (
                      <UserDetailRow
                        question="When did you apply in terms of your qualification?"
                        answer={yearQualification}
                      />
                    )}
                  {isAnswerNotEmpty(getApplicationTimeName(applicationTimeFinalGrades)) && (
                      <UserDetailRow
                        question="Did you apply before or after you had your final grades?"
                        answer={getApplicationTimeName(applicationTimeFinalGrades)}
                      />
                    )}
                    {isAnswerNotEmpty(getGenderName(gender)) && (
                      <UserDetailRow
                        question="What’s your gender?"
                        answer={getGenderName(gender)}
                      />
                    )}
                    {isAnswerNotEmpty(nationality) && (
                      <UserDetailRow
                        question="What’s your nationality?"
                        answer={nationality}
                      />
                    )}
                    {isAnswerNotEmpty(highSchoolCountry) && (
                      <UserDetailRow
                        question="Where did you go to high school?"
                        answer={highSchoolCountry}
                      />
                    )}
                    {isAnswerNotEmpty(GCSE) && (
                      <UserDetailRow
                        question="If you took GCSE or a similar equivalent, what grades did
                      you get?"
                        answer={GCSE}
                      />
                    )}
                    {isAnswerNotEmpty(subjectsAndGrades) && (
                      <UserDetailRow
                        question="What subjects and grades did you achieve? Please include
                      information on things like AS-grades (if applicable),
                      predictions, etc."
                        answer={subjectsAndGrades}
                      />
                    )}
                    {isAnswerNotEmpty(advancedPapers) && (
                      <UserDetailRow
                        question="Did you take any advanced papers, like Advanced Extension
                      Awards, S-papers, Cambridge Maths STEP exams etc? Did you
                      have a predicted grade when you applied? How did you do?"
                        answer={advancedPapers}
                      />
                    )}
                    {isAnswerNotEmpty(otherQualifications) && (
                      <UserDetailRow
                        question="Did you have any other qualifications, awards, etc?"
                        answer={otherQualifications}
                      />
                    )}
                    {isAnswerNotEmpty(helpFromSchool) && (
                      <UserDetailRow
                        question="What kind of help did you get from your school/college prior
                      to your interview or application?"
                        answer={helpFromSchool}
                      />
                    )}
                  </ExpansibleBlock>}

                {showSection(sectionTwo) && <ExpansibleBlock title={sectionTitles[2]} icon="Detail" defaultExpanded>
                    {preInterviewExams &&(
                      <UserDetailRow
                        question="What, if any, pre-interview exams did you have to take (LNAT/BMAT/..)?"
                        answer={preInterviewExams}
                      />
                    )}
                    {isAnswerNotEmpty(writtenWork) && (
                      <UserDetailRow
                        question="What, if any, written work did you have to submit prior to the interview?"
                        answer={writtenWork}
                      />
                    )}
                    {isAnswerNotEmpty(interviewExams) && (
                      <UserDetailRow
                        question="What, if any, exams did you have to take as part of the interview?"
                        answer={interviewExams}
                      />
                    )}
                    {isAnswerNotEmpty(interviewInGeneral) && (
                      <UserDetailRow
                        question="How was/were in general your interview(s)? Anything stood out in a good way or bad way, or surprised you?"
                        answer={interviewInGeneral}
                      />
                    )}
                    {isAnswerNotEmpty(questionsAtInterview) && (
                      <UserDetailRow
                        question="What questions were you asked during your interview(s)?"
                        answer={questionsAtInterview}
                      />
                    )}
                    {isAnswerNotEmpty(clothesAtInterview) && (
                      <UserDetailRow
                        question="What did you wear to your interview(s), and why?"
                        answer={clothesAtInterview}
                      />
                    )}
                    {isAnswerNotEmpty(generalCollegeImpression) && (
                      <UserDetailRow
                        question="What was your general impression of the college that you applied to/any others you visited?"
                        answer={generalCollegeImpression}
                      />
                    )}
                    {isAnswerNotEmpty(collegeAccommodation) && (
                      <UserDetailRow
                        question="How about the day-to-day aspects of living in the college. If you stayed in college, how were the accommodations? How about the food?"
                        answer={collegeAccommodation}
                      />
                    )}
                  </ExpansibleBlock>}

                  {showSection(sectionThree) && <ExpansibleBlock title={sectionTitles[3]} icon="Decision" defaultExpanded>
                    {isAnswerNotEmpty(waitingGettingDecision) && (
                      <UserDetailRow
                        question="How did you feel during the waiting period? What happened when you received the letter/the call telling you whether you had a place or not ?"
                        answer={waitingGettingDecision}
                      />
                    )}
                    {isAnswerNotEmpty(offerType) && (
                      <UserDetailRow
                        question="What type of offer?"
                        answer={
                          offerType === "U" ? "Unconditional" : "Conditional"
                        }
                      />
                    )}
                    {isAnswerNotEmpty(offerConditions) && (
                      <UserDetailRow
                        question="What were the conditions of your offer?"
                        answer={offerConditions}
                      />
                    )}
                    {isAnswerNotEmpty(offerAccepted) && (
                      <UserDetailRow
                        question="Did you accept the offer?"
                        answer={offerAccepted ? "Yes" : "No"}
                      />
                    )}
                    {isAnswerNotEmpty(offerNotAcceptedReason) && (
                      <UserDetailRow
                        question="Why did you not accept the offer?"
                        answer={offerNotAcceptedReason}
                      />
                    )}
                    {isAnswerNotEmpty(getOfferConditionsName(offerConditionsMet)) && (
                      <UserDetailRow
                        question="Did you meet the conditions of your offer?"
                        answer={getOfferConditionsName(offerConditionsMet)}
                      />
                    )}
                  </ExpansibleBlock>}

                  {showSection(sectionFour) && <ExpansibleBlock title={sectionTitles[4]} icon="Applicant" defaultExpanded>
                    {isAnswerNotEmpty(wouldStillApply) && (
                      <UserDetailRow
                        question="If you were to re-live that year of your life again, would you still apply?"
                        answer={wouldStillApply}
                      />
                    )}
                    {isAnswerNotEmpty(adviceForApplicants) && (
                      <UserDetailRow
                        question="What advice do you have for potential applicants based on your experiences? Any recommendations for how best to prepare?"
                        answer={adviceForApplicants}
                      />
                    )}
                  </ExpansibleBlock>}

                  { state === "A" && contactMe && <ExpansibleBlock title="Contact applicant" icon="thumb" defaultExpanded>
                    <UserDetailRow
                      question=""
                      //answer={adviceForApplicants}
                      style={{ textAlign: "left" }}
                      // content={<ContactForm style={{ textAlign: "center", marginTop: "35px", display: "inline-block" }}/>}
                    content={this.state.isMessageSent
                      ? "Thanks! We've forwarded your question to the owner of this profile."
                      : <ContactForm style={styles.contactForm} onSubmit={this.handleSubmitContactForm} />}
                    />
                  </ExpansibleBlock>}
                </div>

              </>
            )}
      </Content>
    );
  }
}

export default UserScreen;
