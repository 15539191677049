import React from "react";

// components
import Textarea from "../components/Textarea";
import Question from "../components/Question";

import { PATHS } from '../App';

// external libs
import { ErrorMessage } from "formik";

// material
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const initialValues4 = {
  wouldStillApply: "",
  adviceForApplicants: "",
  canContactMe: false,
  gdpr: false
};

const style = {
  formControlLabel: {
    marginLeft: "3px"
  },
  errorMessage: {
    color: "red",
    paddingLeft: "17px"
  },
  ppLink: {
    display: "inline-block",
  }
}

const FormPart4 = props => {
  return (
    <div style={{ marginTop: "26px" }}>
      <Question label="If you were to re-live that year of your life again, would you still apply?">
        <Textarea
          id="wouldStillApply"
          value={props.values.wouldStillApply}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What advice do you have for potential applicants based on your experiences? Any recommendations for how best to prepare?">
        <Textarea
          id="adviceForApplicants"
          value={props.values.adviceForApplicants}
          onChange={props.handleChange}
        />
      </Question>

      <Question>
        <FormControlLabel
          style={style.formControlLabel}
          control={
            <Checkbox
              id="contactMe"
              onChange={props.handleChange}
              checked={props.values.contactMe}
              color="primary"
            />
          }
          label="Offer an option for readers to ask me questions via a form on my profile (you will get these questions via email from us — your email will not be shown to anyone)."
        />
      </Question>

      <Question>
        <FormControlLabel
          style={style.formControlLabel}
          control={
            <Checkbox id="gdpr" onChange={props.handleChange} checked={props.values.gdpr} color="primary" />
          }
          label={
            <span>GDPR Agreement:
                I  consent to having this website store my submitted information so they can make my profile available to others
                (<a target="_blank" rel="noopener noreferrer" href={PATHS.privacy}>privacy policy</a>).
            </span>
          }
        />

        <ErrorMessage style={style.errorMessage} name="gdpr" component={"div"} />
      </Question>
    </div>
  );
};

export default FormPart4;
