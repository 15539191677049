export default function isMobileView() { return window.innerWidth < 500; }

let previousLayout = isMobileView()
export function onWindowResize(callback) {
  let currentLayout = isMobileView()
  if (currentLayout === previousLayout) return

  previousLayout = currentLayout
  callback()
}
