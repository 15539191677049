import React, { Component } from "react";
import {Helmet} from "react-helmet";

import "../App.scss";

// external libs
import { Formik, ErrorMessage } from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from 'react-router-dom';

// components
import Content from "../components/Content";
import Error from "../components/Error";

// api
import { signUp } from "../api/api";

const styles = {
  sendButton: {
    backgroundColor: "#00326b",
    height: "45px",
    width: "240px"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold"
  },
  textInput: {
    margin: "0 0 8px 0",
    width: "100%",
    maxWidth: "300px"
  }
};

const validate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

function formatErrors(e = '') {
  if (e.includes('sign in')) {
    let parts = e.split('sign in',)
    return <span>{parts[0]}<Link to='signin'>sign in</Link>{parts[1]}</span>
  }
  return e
}

class SignUpScreen extends Component {
  state = {
    formSent: false,
    errors: ""
  };

  render() {
    return (
      <Content style={{ flexDirection: "column", alignItems: "center", paddingTop: "60px" }}>
        <Helmet>
            <meta name="robots" content="index, follow" />
            <title>Sign up</title>
        </Helmet>
        <h1 style={{ marginBottom: "30px" }}>Sign up</h1>
        {!this.state.formSent && (
          <Formik
            initialValues={{
              email: "",
              name: ""
            }}
            validate={validate}
            onSubmit={(values, { setSubmitting }) => {
              signUp({ email: values.email, name: values.name })
                .then(() => this.setState({ formSent: true, errors: "" }))
                .catch(err => {
                  const errors = (err.response && err.response.data.nonFieldErrors) || [];
                  this.setState({ errors: errors.join(" ") });
                })
                .finally(() => setSubmitting(false));
            }}
          >
            {({ values, handleChange, handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <TextField
                    id="name"
                    label="Your name"
                    value={values.name}
                    onChange={handleChange}
                    variant="outlined"
                    style={styles.textInput}
                  />
                  <Error>
                    <ErrorMessage name="name" component={"div"} />
                  </Error>
                </div>

                <div>
                  <TextField
                    id="email"
                    label="Your email"
                    value={values.email}
                    onChange={handleChange}
                    variant="outlined"
                    style={styles.textInput}
                  />
                  <Error>
                    <ErrorMessage name="email" component={"div"} />
                  </Error>
                </div>

                <Button
                  type="submit"
                  disabled={isSubmitting}
                  style={styles.sendButton}
                >
                  <span style={styles.buttonText}>Sign Up</span>
                </Button>
              </form>
            )}
          </Formik>
        )}
        {this.state.formSent && (
          <p>We have sent you a link to log in. Please check your inbox.</p>
        )}
        {this.state.errors && (
          <p>{formatErrors(this.state.errors)}</p>
        )}
        <p>Your name and email are strictly private and not shown anywhere on your profile. If you don't trust this site and want to submit your profile even more anonymously, feel free to create a one-off email address using a service like <a href="https://www.mailinator.com/" target="_blank" rel="noopener noreferrer">Mailinator</a> and sign up with that email address (note though that unless you save that email address, you will not be able to edit your profile in the future).</p>
      </Content>
    );
  }
}

export default SignUpScreen;
