import React, { Component } from "react";
import {Helmet} from "react-helmet";

import "../App.scss";

// components
import Content from "../components/Content";

class PrivacyScreen extends Component {
  render() {
    return (
      <Content style={{ flexDirection: "column", textAlign: "left", paddingTop: "60px" }}>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title>Oxford Admissions - Privacy Policy</title>
          <meta name="description" content="Privacy Policy for the Oxbridge Admissions site – a leading online resource for real-world Oxford and Cambridge application advice" />
          <meta name="keywords" content="Oxbridge, Oxford University, Cambridge University, Oxbridge Admissions" />
        </Helmet>
        <h1 style={{ marginBottom: "30px", color: "#00326b" }}>Privacy policy</h1>
        <h2 style={{ fontSize: "18px" }}>Data protection</h2>
        <p>
          <b>
            When you submit a profile, you are giving us permission to store the
            information you have entered above in our database
          </b>
          , so that it may be retrieved when people view the profiles. We will
          not disclose any personal information whatsoever, other than
          displaying your profile on our website.
        </p>
        <p>
          <b>Your e-mail address will not be passed on to a third party</b>, and
          will not be published anywhere on the website. If you agreed to
          receive questions from prospective applicants, you will receive those
          questions through us, your e-mail address will not be published. The
          contact form is also protected against spam bots. You are always able
          to change the settings of your profile and disable this option.
        </p>
        <p>
          You may receive a direct e-mail informing you about news and updates
          on this website with a maximum frequency of 1 e-mail per month.
        </p>

        <h2 style={{ fontSize: "18px" }}>Requesting changes</h2>

        <p>
          <b>
            If you have a profile published on this website without having a
            'registered user' account, i.e. access to your profile, you may
            contact us and request your login details.
          </b>
          <br />
          You will then gain full access to your profile and you will be able to
          edit or delete it.
        </p>

        <p>
          If you simply wish your profile to be deleted, and you do not have a
          'registered user' account, you may contact us and
          <b> request removing your profile from the database.</b>
        </p>
      </Content>
    );
  }
}

export default PrivacyScreen;
