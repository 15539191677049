import React from "react";

const styles = {
  question: {
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "left"
  },
  answer: {
    fontSize: "14px",
    textAlign: "left"
  }
};

const UserDetailRow = props => {

  let answer
  if (props.answer && typeof(props.answer) == "string") {
    const tempAnswer = props.answer.replace(/&quot;/g, '"')
    answer = tempAnswer.split('<br />').map((item, i) => {
      return <p key={i}>{item.trim()}</p>
    })
  } else {
    answer = props.answer
  }

  return (
    <div style={{ textAlign: "left" }}>
      <p style={styles.question}>{props.question}</p>
      {answer}
      {props.content && props.content}
    </div>
  );
};

export default UserDetailRow;
