import React, { Component } from "react";
import {Helmet} from "react-helmet";

// material
import Button from "@material-ui/core/Button";

// external libs
import { Formik } from "formik";
import { Redirect } from "react-router-dom";

// utils
import { PATHS } from '../App';
import images from "../images/images";

// components
import Content from "../components/Content";
import ExpansibleBlock from "../components/ExpansibleBlock";
import FormPart1, { initialValues1 } from "../components/FormPart1";
import FormPart2, { initialValues2 } from "../components/FormPart2";
import FormPart3, { initialValues3 } from "../components/FormPart3";
import FormPart4, { initialValues4 } from "../components/FormPart4";

// api
import {
  createProfile,
  getUniversities,
  getMyProfile,
  updateProfile
} from "../api/api";
import { setMyProfile } from './SignIn';

export const sectionTitles = {
  1: 'Part 1: Application',
  2: 'Part 2: Interview',
  3: 'Part 3: Final outcome',
  4: 'Part 4: Looking back',
}

const validate = values => {
  let errors = {};
  if (!values.gdpr) {
    errors.gdpr = "Required";
  }
  if (!values.university) {
    errors.university = "Required";
  }
  if (!values.college) {
    errors.college = "Required";
  }
  if (!values.course) {
    errors.course = "Required";
  }
  if (!values.year) {
    errors.year = "Required";
  }
  if (!values.nationality) {
    errors.nationality = "Required";
  }
  if (!values.highSchoolCountry) {
    errors.highSchoolCountry = "Required";
  }
  if (!values.applicationTimeFinalGrades) { // was 'yearQualification' before
    errors.applicationTimeFinalGrades = "Required";
  }
  if (!values.gender) {
    errors.gender = "Required";
  }
  if (!values.applicationOutcome) {
    errors.applicationOutcome = "Required";
  }
  if (!values.schoolLeavingExams) {
    errors.schoolLeavingExams = "Required";
  }
  return errors;
};

const styles = {
  errorGlobalLabel: {
    color: 'red',
    marginTop: 18,
    marginBottom: -18
  },
  sendButton: {
    backgroundColor: "#00326b",
    borderRadius: "20px",
    padding: "4px 17px 5px",
    textTransform: "none",
    marginTop: "36px"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold"
  },
  form: {
    paddingTop: "20px"
  },
  headline: {
    padding: "24px 60px", // was 4Opx top?
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headlineInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

class App extends Component {
  state = {
    universities: [],
    myProfile: null,
    formSent: false,
    submitError: false,
  };

  componentDidMount() {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      window.location.href = PATHS.signin;
    }

    getMyProfile().then(response => {
      this.setState({ myProfile: response.data });
    });

    getUniversities().then(response => {
      this.setState({ universities: response.data });
    });
  }

  saveForm = values => this.state.myProfile.uuid
    ? updateProfile(this.state.myProfile.uuid, values)
    : createProfile(values)

  render() {
    if (!this.state.universities.length || !this.state.myProfile) return 'Loading...'
    const { profileId, state } = this.state.myProfile
    const buttonLabel = { 'P': 'Update', undefined: 'Submit' }[state] || 'Resubmit' // P,A,D,undefined - pending,accepted,rejected,new

    return (
      <Content style={{ paddingTop: "60px", flexDirection: 'column' }}>
        {!this.state.formSent && (
          <>
            <Helmet>
                <meta name="robots" content="index, follow" />
                <title>Add profile</title>
            </Helmet>
            {state && <div style={styles.headline}>
              <div style={{ ...styles.headlineInner, width: '100%' }}>
                <img
                  src={images.profile}
                  alt={profileId}
                  style={{ marginRight: "9px" }}
                />
                <div style={{ position: "relative", flex: 1 }}>
                  <div
                    style={{
                      fontSize: "15px",
                      color: "#000",
                      fontWeight: 500,
                      textAlign: "left",
                      lineHeight: "10px"
                    }}
                  >
                    {state === 'A' && 'Publicly visible'}
                    {state === 'P' && 'Pending approval'}
                    {state === 'D' && 'Profile rejected'}
                  </div>
                </div>
              </div>
            </div>}
            <Formik
              enableReinitialize
              style={styles.form}
              initialValues={{
                // 1. <ErrorMessage/> only renders if touched === true and error === true
                // 2. All fields must be set in initialValues otherwise formik won't register them
                // so, this fixes formik bug where not all fields are set to 'touched=true' on form submit, which then prevents the error message
                // Better to use react-final-form next time
                ...{ gdpr: false },
                // ...
                ...this.state.myProfile.university
                  ? {
                    ...this.state.myProfile,
                    gdpr: true,
                  }
                  : {
                    ...initialValues1,
                    ...initialValues2,
                    ...initialValues3,
                    ...initialValues4,
                  }
              }}
              validate={validate}
              onSubmit={(values, { setSubmitting }) => {
                this.setState({
                  formSent: false,
                  submitError: false,
                })
                this.saveForm(values)
                  .then(response => {
                    setMyProfile(response)
                    this.setState({ formSent: true, myProfile: response.data });
                    localStorage.setItem("profileState", "P");
                  })
                  .catch(() => {
                    this.setState({ submitError: true });
                  })
                  .finally(() => setSubmitting(false));
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                errors,
                submitCount,
              }) => (
                  <form onSubmit={handleSubmit}>
                    <ExpansibleBlock title={sectionTitles[1]} icon='Application' defaultExpanded>
                      <FormPart1
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        universities={this.state.universities}
                      />
                    </ExpansibleBlock>

                    <ExpansibleBlock title={sectionTitles[2]} icon='Application' defaultExpanded>
                      <FormPart2 values={values} handleChange={handleChange} />
                    </ExpansibleBlock>

                    <ExpansibleBlock title={sectionTitles[3]} icon='Application' defaultExpanded>
                      <FormPart3 values={values} handleChange={handleChange} />
                    </ExpansibleBlock>

                    <ExpansibleBlock title={sectionTitles[4]} icon='Application' defaultExpanded>
                      <FormPart4 values={values} handleChange={handleChange} />
                    </ExpansibleBlock>

                    {!!submitCount && !!Object.keys(errors).length && (
                      <p style={styles.errorGlobalLabel}>
                        You're missing some mandatory information — we've marked those in red on the form above.
                    </p>
                    )}

                    {this.state.submitError && (
                      <p style={styles.errorGlobalLabel}>
                        Something is wrong on our side, please try again later.
                    </p>
                    )}

                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      style={styles.sendButton}
                    >
                      <span style={styles.buttonText}>{buttonLabel} my profile</span>
                    </Button>
                  </form>
                )}
            </Formik>
          </>
        )}
        {this.state.formSent && (
          <p>
            {/* Form has been sent. <Link to="/">Go to profile listing</Link>. */}
            Form has been sent. <Redirect to={`/profile/${this.state.myProfile.id}`}>Go to profile listing</Redirect>.
          </p>
        )}
      </Content>
    );
  }
}

export default App;
