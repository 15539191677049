import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

// components
import Logo from "./Logo";

// utils
import HamburgerMenu from "./HamburgerMenu";
import MobilePanel from "./MobilePanel";
import { PATHS } from '../App';

// api
import { setDefaultHeader, logOut } from "../api/api";
import isMobileView from '../utils/isMobileView';

const styles = {
  header: {
    height: "60px",
    backgroundColor: "#093769",
    display: "flex",
    justifyContent: "space-between",
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 1
  },
  headerLink: {
    color: "white",
    textDecoration: "none",
    marginBottom: "0px",
    marginTop: "0px",
    marginRight: "25px",
    marginLeft: "0px",
  },
  headerLinkMobile: {
    color: "white",
    textDecoration: "none",
    paddingLeft: "15px"
  },
  linkWrapper: {
    display: "flex",
    alignItems: "center"
  },
  logoWrapper: {
    height: "60px",
    width: "150px",
    display: "flex",
    alignItems: "center"
  },
  profileNavigation: {
    display: "flex",
    alignItems: "center"
  },
  separator: {
    margin: "10px 25px",
    borderBottom: "1px solid #FFF4"
  },
  href: {
    cursor: "pointer"
  },
  logos: {
    display: "flex",
    width: "130px",
    paddingLeft: "10px",
    justifyContent: "space-around",
    alignItems: "baseline"
  },
  cambridgeLogo: {
    mixBlendMode: "lighten"
  }
};

const handleSignOut = (event) => {
  event.preventDefault();
  setDefaultHeader("Authorization");
  localStorage.clear()
  logOut()
  // localStorage.removeItem("authToken");
  // localStorage.removeItem("isAdmin");
  window.location.href = "/"; // TODO should use history.push
};

const renderNotLoggedIn = () => (
  <>
    {/* <Link to={PATHS.signup} style={styles.headerLink}>
      Sign up
    </Link> */}
    <Link to={PATHS.signin} style={styles.headerLink}>
      Sign in
    </Link>
    <Link
      to={PATHS.about}
      style={{ ...styles.headerLink }}
    >
      About
    </Link>
    <a href='http://blog.oxbridgeadmissions.com' style={{ ...styles.headerLink, ...styles.href }}>
      Blog
    </a>
    <Link
      to={PATHS.privacy}
      style={{ ...styles.headerLink }}
    >
      Privacy policy
    </Link>
  </>
);

const renderLoggedIn = profileState => (
  <>
    <Link to={profileState ? PATHS.profileId(localStorage.getItem('profile')) : PATHS.form} style={styles.headerLink}>
      {/* {(profileState === "A" || profileState === "P") && "My profile"} */}
      {/* {profileState === "D" && "Resubmit"} */}
      {/* {!profileState && "Add profile"} */}
      {
        profileState
          ? 'My profile'
          : 'Add profile'
      }
    </Link>
    <Link
      to={PATHS.about}
      style={{ ...styles.headerLink }}
    >
      About
    </Link>
    <a href='http://blog.oxbridgeadmissions.com' style={{ ...styles.headerLink, ...styles.href }}>
      Blog
    </a>
    <Link
      to={PATHS.privacy}
      style={{ ...styles.headerLink }}
    >
      Privacy policy
    </Link>
    <div className="MobilePanel-separator" style={styles.separator} />
    <a href={PATHS.home} onClick={handleSignOut} style={{ ...styles.headerLink, ...styles.href }}>
      Sign out
    </a>
  </>
);


class Header extends Component {
  state = {
    menuOpen: false
  };

  toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })

  componentDidMount() {
    document.body.classList.remove('menuOpen')
  }

  componentDidUpdate() {
    this.state.menuOpen
      ? document.body.classList.add('menuOpen')
      : document.body.classList.remove('menuOpen');
  }

  render() {
    const authToken = localStorage.getItem("authToken");
    const profileState = localStorage.getItem("profileState");
    const { match: { params: { profileId } }, location: { state } } = this.props

    const isStateNotEmpty = state && !!Object.keys(state).length
    const currentIndex = isStateNotEmpty && ( profileId
      ? state.users.findIndex(profile => profile.id == profileId ) // eslint-disable-line eqeqeq
      : state._lastDisplayedProfileIndex )
    if (isStateNotEmpty) state._lastDisplayedProfileIndex = currentIndex
    const getNextProfilePath = offset =>
      isStateNotEmpty && state.users[currentIndex + offset]
        ? PATHS.profileId(state.users[currentIndex + offset].id)
        : PATHS.home
    const getNextProfileTitle = offset =>
      isStateNotEmpty && state.users[currentIndex + offset]
        ? `Show profile ${state.users[currentIndex + offset].profileId}`
        : `Show all profiles`

    return (
      <>
        <div style={{ ...styles.header, padding: isMobileView() ? "" : "0 0 0 25px" }}>
          <div style={styles.linkWrapper}>
            {/*<div style={styles.logos}>
              <div>
                <img src={images.oxford} alt="oxford" />
              </div>
              <div>
                <div style={styles.separator} />
              </div>
              <div>
                <img
                  src={images.cambridge}
                  style={styles.cambridgeLogo}
                  alt="cambridge"
                />
              </div>
            </div>*/}


            {
              isMobileView()
                ? <Link to={PATHS.home} style={styles.headerLinkMobile}>OA</Link>
                : <div style={styles.logoWrapper}><Logo/></div>
            }

          </div>
          {
            isStateNotEmpty && this.props.match.path === "/profile/:profileId" && <div style={styles.profileNavigation}>
              <Link
                to={{ pathname: getNextProfilePath(-1), state }}
                style={styles.headerLink}
                title={getNextProfileTitle(-1)}
              >
                ←
              </Link>
              {this.props.match.path === "/profile/:profileId" && (
                  <div style={styles.headerLink}>
                    {currentIndex + 1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/
                  </div>
              )}

              <Link
                to={{ pathname: PATHS.home, state }}
                style={styles.headerLink}
                title={`Show all ${state.count} profiles`}
              >
                {state.count}
              </Link>

              <Link
                to={{ pathname: getNextProfilePath(+1), state }}
                style={styles.headerLink}
                title={getNextProfileTitle(+1)}
              >
                →
              </Link>
            </div>
          }
          <div style={styles.linkWrapper}>
            <HamburgerMenu isOpen={this.state.menuOpen} onClick={this.toggleMenu} />
          </div>
        </div>
        {this.state.menuOpen && (
          <MobilePanel toggleHandler={this.toggleMenu}>
            {authToken ? renderLoggedIn(profileState) : renderNotLoggedIn()}
          </MobilePanel>
        )}
      </>
    );
  }
};

export default withRouter(Header)
