const getOfferConditionsName = condition => {
  switch (condition) {
    case "OM":
      return "Offer met";
    case "OMSA":
      return "Offer missed, still accepted";
    case "OMSP":
      return "Offer missed, summer pooled";
    case "OMNA":
      return "Offer missed, was not accepted";
    case "GP/U":
      return "Grades pending/unknown";
    default:
      return "";
  }
};

export default getOfferConditionsName;
