import React, { Component } from "react";
import {Helmet} from "react-helmet";

import "../App.scss";

// external libs
//import { Link } from "react-router-dom";

// components
import Content from "../components/Content";
import Grid from "../components/Grid";
import Contact from "../components/Contact";

class AboutScreen extends Component {
  render() {
    return (
      <Content wide style={{
        padding: "0 0 60px 0"
      }}>
        <Helmet>
          <meta name="robots" content="index, follow" />
          <title>Oxbridge Admissions - About Us - Learn More About Oxford and Cambridge Admissions</title>
          <meta name="description" content="For anyone thinking about applying to Oxford University or University of Cambridge, browse our profiles from students who have already gone through the process. Learn valuable insights for all Oxbridge programs." />
          <meta name="keywords" content="Oxbridge, Oxford University, Cambridge University, Oxbridge Admissions, Oxbridge Applications, Jan Sramek" />
        </Helmet>
        <Grid width5O alignRight spaceTop style={{
          display: "inline-flex"
        }}>
          <Grid
            column
            style={{
              textAlign: "left",
              maxWidth: "500px",
              paddingLeft: "0",
              paddingRight: "0"
            }}
          >
            {/* <h1 style={{
              color: "#00326b"
            }}>About this project</h1> */}
            <h3>About this project</h3>
            <p>
              Applying to Oxford and Cambridge (aka "Oxbridge") is intimidating.
              Mostly you don’t know what to expect or what will be expected of you.
              That is especially the case if you are coming from a school that
              has not sent many (or any) students to Oxbridge. On the other hand,
              many prestigious private schools spend significant resources
              to encourage, guide, and prepare their students
              for the Oxbridge application process.
            </p>
            <p>
              One of the tools typically available to students at such schools
              is a book of written reports by past Oxbridge applicants from the school.
              In these reports, those students explain how they applied,
              how they prepared, what their interview was like, and whether
              they got an offer. Reading dozens or hundreds of such reports
              both gives you an actual information advantage
              and can help boost your confidence.
            </p>
            <p style={{marginBottom: "0px"}}>
              In 2006, we founded <a href="https://oxbridgeadmissions.com">oxbridgeadmissions.com</a>{" "}
              to level the playing field and make similar Oxbridge application
              reports available to everyone. We want your chances of admission
              to be based on your academic merit, not what school you happen to attend.
              We hope the reports can help you figure out:
            </p>
            <ul>
              <li>whether to apply (we encourage you to, if you have the requisite grades),</li>
              <li>which university and college to apply for,</li>
              <li>how to write a good application,</li>
              <li>and what to expect in the interviews.</li>
            </ul>
            <p>
              If you have any questions or suggestions <b>about this site</b> [1], 
              feel free to reach out to me at <a href="mailto:jan@oxbridgeadmissions.com">jan@oxbridgeadmissions.com</a>. 
              And if you do decide to apply, good luck!
            </p>
            <p>
              Jan Sramek, Founder<br/>
              Mathematics at Trinity, Cambridge (2006)
            </p>
            <p>
            [1] Please note that I can only answer questions or suggestions about this site itself, not about your individual application, 
            funding options, etc. Please don't spend time asking me about the latter, I unfortunately will not be able to answer your email.
            </p>
          </Grid>
        </Grid>
        <Grid width5O fullHeight spaceLeft spaceTop alignLeft style={{
          display: "inline-flex",
          backgroundColor: "#efefef"
        }}>
          <Grid
            column
            style={{
              textAlign: "center",
              maxWidth: "320px",
              paddingLeft: "0",
              paddingRight: "0"
            }}
          >
            <h3>Contact us</h3>
            <Contact />
          </Grid>
        </Grid>
      </Content>
    );
  }
}

export default AboutScreen;
