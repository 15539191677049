import React from "react";
import styled from "styled-components";
import isMobileView from '../utils/isMobileView';

const MobilePanelStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  padding-top: 20px;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #093769;
  border-top: solid 1px #fff;
  cursor: pointer;

  a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ${() => !isMobileView() && `
    left: unset;
    top: 0;
    right: 0;
    width: 298px;
    height: 100%;
    padding: 80px 20px 0 30px;
    border-top: 0px;
    background-color: #093769E0;
  `}
`;

const OverlayContainer = styled.div`
  ${() => !isMobileView() && `
    background-color: #000C;
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 998;
  `}
`

const MobilePanel = ({ children, toggleHandler, ...restProps }) => {
  return (
    <OverlayContainer onClick={toggleHandler}>
      <MobilePanelStyled className='MobilePanel' {...restProps}>
        {children}
      </MobilePanelStyled>
    </OverlayContainer>
  );
};

export default MobilePanel;
