import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

// styles
import "./App.scss";

// external libs
import i18n from "i18n-js";

// api
import { setDefaultHeader, currentUser } from "./api/api";

// screens
import About from "./screens/About";
//import Contact from "./screens/Contact";
import Form from "./screens/Form";
import Users from "./screens/Users";
import User from "./screens/User";
import SignUp from "./screens/SignUp";
import SignIn, { /* setMyProfile, */ verifyLogin } from "./screens/SignIn";
import Privacy from "./screens/Privacy";

import { onWindowResize } from './utils/isMobileView';

import en from "./i18n/en";
i18n.translations = { en };

const FormScreen = () => <Form />;
//const ContactScreen = () => <Contact />;
const UsersScreen = routeProps => <Users {...routeProps} />;
const UserScreen = routeProps => <User key={routeProps.match.params.profileId} {...routeProps} />;
const AboutScreen = () => <About />;
const SignInScreen = routeProps => <SignIn {...routeProps} />;
const SignUpScreen = () => <SignUp />;
const PrivacyScreen = () => <Privacy />;

export const PATHS = {
  form: '/form',
  profile: '/profile',
  profileId: id => `${PATHS.profile}/${id}`,
  about: '/about',
  signin: '/signin',
  signup: '/signup',
  privacy: '/privacy',
  home: '/',
}

class AuthComponent extends Component {
  state = { shouldRender: false }

  async componentDidMount() {
    const { history, location: { state } } = this.props;

    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");

    const loginCode = code || (state && state.loginCode)

    if (loginCode)
      try {
        await verifyLogin(loginCode) // also runs setMyProfile()
        history.replace({ state: {} })
      } catch (err) {
        console.error('%O', err)
        history.push(PATHS.signin) // sign in failed, redirect
      }

    this.setState({ shouldRender: true })
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setInterval(() => currentUser(), 7000);
    }
  }

  render = () => this.state.shouldRender ? this.props.children : 'Loading...'
}

class App extends Component {
  componentWillMount() {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      setDefaultHeader("Authorization", "Token " + authToken);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize)
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    const ga_script_1 = document.createElement("script");
    ga_script_1.src = "https://www.googletagmanager.com/gtag/js?id=UA-2364574-4";

    const ga_script_2 = document.createElement("script");
    ga_script_2.innerText = "window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-2364574-4');";

    const s = document.head.firstChild;
    s.parentNode.insertBefore(ga_script_1, s);
    s.parentNode.insertBefore(ga_script_2, s);

    window.addEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => onWindowResize(() => this.forceUpdate())

  render() {
    return (
      <CookiesProvider>
        <Router>
          <Route render={routeProps =>
            <AuthComponent {...routeProps}>
              <Switch>
                {/* <Route path="/contact" exact component={ContactScreen} /> */}
                <Route path={PATHS.form} exact component={FormScreen} />
                <Route path={`${PATHS.profile}/:profileId`} exact component={UserScreen} />
                <Route path={PATHS.about} exact component={AboutScreen} />
                <Route path={PATHS.signin} exact component={SignInScreen} />
                <Route path={PATHS.signup} exact component={SignUpScreen} />
                <Route path={PATHS.privacy} exact component={PrivacyScreen} />
                <Route path={`${PATHS.home}:universityFilter?/:collegeFilter?/:courseFilter?/:yearFilter?/:outcomeFilter?/:statusFilter?`} exact component={UsersScreen} />
              </Switch>
            </AuthComponent>}
          />
        </Router>
      </CookiesProvider>
    );
  }
}

export default App;
