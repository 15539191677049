import React from "react";
import styled from "styled-components";

const ErrorStyled = styled.div.attrs({
  className: "Error"
})`
  display: flex;
  text-align: left;
  position: relative;
  top: -5px;
  color: red;
  font-size: 12px;
  padding-left: 14px;
`;

const Error = ({ children, ...restProps }) => {
  return (
    <ErrorStyled {...restProps}>
      {children}
    </ErrorStyled>
  );
};

export default Error;
