import React from "react";

// material
import FormControl from "@material-ui/core/FormControl";

import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// components
import Textarea from "../components/Textarea";
import Question from "../components/Question";

// external libs
import { ErrorMessage } from "formik";

// utils
import getYearsOptions from "../utils/getYearsOptions";
import getNationalityOptions from "../utils/getNationalityOptions";
import getCountryOptions from "../utils/getCountryOptions";
import { findEntity } from '../utils';
import { getComparator } from '../screens/Users';
import getSchoolLeavingExamsOptions from '../utils/getSchoolLeavingExamsOptions';

export const initialValues1 = {
  university: "06bf57c4-d688-4b67-a730-70692a34746b", // cambridge
  college: "3eb70598-710f-4310-aa91-b3f707cdc251", // this UUID is only for current version on production
  course: "5029cb05-ca1b-4786-a31c-e2541276d67a", // this UUID is only for current version on production
  oxbridgeReason: "",
  collegeApplicationReason: "",
  openApplication: false,
  year: new Date().getMonth() <= 11 ? new Date().getFullYear() - 1 : new Date().getFullYear(),
  deferredEntry: false,
  applicationTimeFinalGrades: "B",
  gender: "F",
  nationality: "UK",
  highSchoolCountry: "GB",
  GCSE: "",
  schoolLeavingExams: getSchoolLeavingExamsOptions()[0]['key'],
  subjectsAndGrades: "",
  advancedPapers: "",
  otherQualifications: "",
  helpFromSchool: ""
};

const style = {
  formControl: {
    width: "100%",
    maxWidth: "500px"
  },
  checkbox: {
    marginLeft: "6px"
  },
  formControlLabel: {
    marginLeft: "0"
  },
  errorMessage: {
    color: "red",
    paddingLeft: "17px",
    marginTop: "10px"
  }
}

const FormPart1 = props => {
  const onUniversityChange = event => {
    // preselect first option on university change
    props.handleChange(event)
    props.setFieldValue('college', findEntity(props.universities, event.target.value).colleges[0].uuid)
    props.setFieldValue('course', findEntity(props.universities, event.target.value).courses[0].uuid)
  }

  const handleCollegeChange = event => {
    props.setFieldValue('college', event.target.value)
  }

  return (
    <div style={{ marginTop: "26px" }}>
      <Question label="Which university did you apply to?" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.university}
            onChange={onUniversityChange}
            input={<OutlinedInput name="university" />}
          >
            {/* <option value="" /> */}
            {props.universities.map(option => (
              <option value={option.uuid} key={option.uuid}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="university" component={"div"} />
      </Question>

      <Question
        label="For what reasons did you apply to Oxbridge? How did you
              decide between Oxford and Cambridge?"
      >
        <Textarea
          id="oxbridgeReason"
          value={props.values.oxbridgeReason}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="oxbridgeReason" component={"div"} />
      </Question>

      <Question
        label="College (if made an open application, allocated college)"
        required
      >
        <FormControl style={style.formControl}>
          <Select
            value={props.values.college}
            onChange={handleCollegeChange}
            disabled={!props.values.university}
            input={<OutlinedInput name="college" />}
          >
            {props.values.university &&
              findEntity(props.universities, props.values.university)
                .colleges
                .sort(getComparator('asc', c => c.name))
                .map(option => (
                  <option value={option.uuid} key={option.uuid}>
                    {option.name}
                  </option>
                ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="college" component={"div"} />

        <div style={{ height: "15px" }} />

        <FormControlLabel
          style={style.formControlLabel}
          control={
            <Checkbox
              id="openApplication"
              onChange={props.handleChange}
              color="primary"
              style={ style.checkbox }
              checked={props.values.openApplication}
            />
          }
          label="Did you make an open application?"
        />
      </Question>

      <Question
        label="Why did you choose this college/make an open application?"
      >
        <Textarea
          id="collegeApplicationReason"
          value={props.values.collegeApplicationReason}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="collegeApplicationReason" component={"div"} />
      </Question>

      <Question label="Course" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.course}
            onChange={props.handleChange}
            disabled={!props.values.university}
            input={<OutlinedInput name="course" />}
          >
            {props.values.university &&
              findEntity(props.universities, props.values.university)
                .courses
                .sort(getComparator('asc', c => c.name))
                .map(option => (
                  <option value={option.uuid} key={option.uuid}>
                    {option.name}
                  </option>
                ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="course" component={"div"} />
      </Question>

      <Question label="Year in (not for!) which you applied:" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.year}
            onChange={props.handleChange}
            input={<OutlinedInput name="year" />}
          >
            {getYearsOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="year" component={"div"} />

        <div style={{ height: "15px" }} />

        <FormControlLabel
          style={style.formControlLabel}
          control={
            <Checkbox
              id="deferredEntry"
              onChange={props.handleChange}
              color="primary"
              style={style.checkbox}
              checked={props.values.deferredEntry}
            />
          }
          label="Deferred entry"
        />
      </Question>

      <Question
        label="Did you apply before or after you had your final grades?"
        required
      >
        <FormControl style={style.formControl}>
          <Select
            value={props.values.applicationTimeFinalGrades}
            onChange={props.handleChange}
            input={<OutlinedInput name="applicationTimeFinalGrades" />}
          >
            <option value="B" key="final_grades-1">
              Before
            </option>
            <option value="A" key="final_grades-2">
              After
            </option>
            {/* {getYearsOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))} */}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="applicationTimeFinalGrades" component={"div"} />
      </Question>

      <Question label="What’s your gender:" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.gender}
            onChange={props.handleChange}
            input={<OutlinedInput name="gender" />}
          >
            <option value="F" key="F">
              Female
            </option>
            <option value="M" key="M">
              Male
            </option>
            <option value="O" key="O">
              Other
            </option>
            <option value="p" key="p">
              Don´t want to say
            </option>
          </Select>
        </FormControl>

        {/* <RadioGroup
          aria-label="Gender"
          name="gender"
          value={props.values.gender}
          onChange={event => props.setFieldValue("gender", event.target.value)}
          style={{ display: "block" }}
        >
          <FormControlLabel
            value="F"
            control={<Radio color="primary" />}
            label="Female"
          />
          <FormControlLabel
            value="M"
            control={<Radio color="primary" />}
            label="Male"
          />
          <FormControlLabel
            value="O"
            control={<Radio color="primary" />}
            label="Other"
          />
          <FormControlLabel
            value="p"
            control={<Radio color="primary" />}
            label="Don´t want to say"
          />
        </RadioGroup> */}
        <ErrorMessage style={style.errorMessage} name="gender" component={"div"} />
      </Question>

      <Question label="What’s your nationality?" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.nationality}
            onChange={props.handleChange}
            input={<OutlinedInput name="nationality" />}
          >
            {getNationalityOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="nationality" component={"div"} />
      </Question>

      <Question label="Where did you go to high school?" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.highSchoolCountry}
            onChange={props.handleChange}
            input={<OutlinedInput name="highSchoolCountry" />}
          >
            {getCountryOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="highSchoolCountry" component={"div"} />
      </Question>

      <Question
        label="If you took GCSE or a similar equivalent, what grades did
                    you get?"
      >
        <Textarea
          id="GCSE"
          value={props.values.GCSE}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="GCSE" component={"div"} />
      </Question>

      <Question label="What school leaving exams did you take?" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.schoolLeavingExams}
            onChange={props.handleChange}
            input={<OutlinedInput name="schoolLeavingExams" />}
          >
            {getSchoolLeavingExamsOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="schoolLeavingExams" component={"div"} />
      </Question>

      <Question
        label="What subjects and grades did you achieve? Please include
                    information on things like AS-grades (if applicable),
                    predictions, etc."
      >
        <Textarea
          id="subjectsAndGrades"
          value={props.values.subjectsAndGrades}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="subjectsAndGrades" component={"div"} />
      </Question>

      <Question
        label="Did you take any advanced papers, like Advanced Extension
                    Awards, S-papers, Cambridge Maths STEP exams etc? Did you
                    have a predicted grade when you applied? How did you do?"
      >
        <Textarea
          id="advancedPapers"
          value={props.values.advancedPapers}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="advancedPapers" component={"div"} />
      </Question>

      <Question label="Did you have any other qualifications, awards, etc?">
        <Textarea
          id="otherQualifications"
          value={props.values.otherQualifications}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="otherQualifications" component={"div"} />
      </Question>

      <Question
        label="What kind of help did you get from your school/college prior to your interview or application?"
      >
        <Textarea
          id="helpFromSchool"
          value={props.values.helpFromSchool}
          onChange={props.handleChange}
        />
        <ErrorMessage style={style.errorMessage} name="helpFromSchool" component={"div"} />
      </Question>
    </div>
  );
};

export default FormPart1;
