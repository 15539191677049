import React from "react";

const styles = {
  stars: {
    color: "#ec5151",
    fontSize: "16px",
    position: "relative",
    bottom: "-4px"
  }
};

const RequireStars = () => {
  return (
    <span style={styles.stars}>*</span>
  );
};

export default RequireStars;