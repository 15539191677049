import React from "react";
import { Link } from "react-router-dom";
import { PATHS } from '../App';

import images from "../images/images";

const styles = {
  logo: {
    display: "flex",
    alignItems: "center"
  },
  link: {
    margin: "auto"
  }
};

const Footer = () => {
  return (
    <Link to={PATHS.home} style={styles.link}>
      <img src={images.oxbridge} style={styles.logo} alt="Oxbridge" />
    </Link>
  );
};

export default Footer;
