const getCountryOptions = () => [
  {
    key: "AF",
    label: "Afghanistan"
  },
  {
    key: "AX",
    label: "Åland Islands"
  },
  {
    key: "AL",
    label: "Albania"
  },
  {
    key: "DZ",
    label: "Algeria"
  },
  {
    key: "AS",
    label: "American Samoa"
  },
  {
    key: "AD",
    label: "Andorra"
  },
  {
    key: "AO",
    label: "Angola"
  },
  {
    key: "AI",
    label: "Anguilla"
  },
  {
    key: "AQ",
    label: "Antarctica"
  },
  {
    key: "AG",
    label: "Antigua and Barbuda"
  },
  {
    key: "AR",
    label: "Argentina"
  },
  {
    key: "AM",
    label: "Armenia"
  },
  {
    key: "AW",
    label: "Aruba"
  },
  {
    key: "AU",
    label: "Australia"
  },
  {
    key: "AT",
    label: "Austria"
  },
  {
    key: "AZ",
    label: "Azerbaijan"
  },
  {
    key: "BS",
    label: "Bahamas"
  },
  {
    key: "BH",
    label: "Bahrain"
  },
  {
    key: "BD",
    label: "Bangladesh"
  },
  {
    key: "BB",
    label: "Barbados"
  },
  {
    key: "BY",
    label: "Belarus"
  },
  {
    key: "BE",
    label: "Belgium"
  },
  {
    key: "BZ",
    label: "Belize"
  },
  {
    key: "BJ",
    label: "Benin"
  },
  {
    key: "BM",
    label: "Bermuda"
  },
  {
    key: "BT",
    label: "Bhutan"
  },
  {
    key: "BO",
    label: "Bolivia"
  },
  {
    key: "BQ",
    label: "Bonaire, Sint Eustatius and Saba"
  },
  {
    key: "BA",
    label: "Bosnia and Herzegovina"
  },
  {
    key: "BW",
    label: "Botswana"
  },
  {
    key: "BV",
    label: "Bouvet Island"
  },
  {
    key: "BR",
    label: "Brazil"
  },
  {
    key: "IO",
    label: "British Indian Ocean Territory"
  },
  {
    key: "BN",
    label: "Brunei"
  },
  {
    key: "BG",
    label: "Bulgaria"
  },
  {
    key: "BF",
    label: "Burkina Faso"
  },
  {
    key: "BI",
    label: "Burundi"
  },
  {
    key: "CV",
    label: "Cabo Verde"
  },
  {
    key: "KH",
    label: "Cambodia"
  },
  {
    key: "CM",
    label: "Cameroon"
  },
  {
    key: "CA",
    label: "Canada"
  },
  {
    key: "KY",
    label: "Cayman Islands"
  },
  {
    key: "CF",
    label: "Central African Republic"
  },
  {
    key: "TD",
    label: "Chad"
  },
  {
    key: "CL",
    label: "Chile"
  },
  {
    key: "CN",
    label: "China"
  },
  {
    key: "CX",
    label: "Christmas Island"
  },
  {
    key: "CC",
    label: "Cocos (Keeling) Islands"
  },
  {
    key: "CO",
    label: "Colombia"
  },
  {
    key: "KM",
    label: "Comoros"
  },
  {
    key: "CG",
    label: "Congo"
  },
  {
    key: "CD",
    label: "Congo (the Democratic Republic of the)"
  },
  {
    key: "CK",
    label: "Cook Islands"
  },
  {
    key: "CR",
    label: "Costa Rica"
  },
  {
    key: "CI",
    label: "Côte d'Ivoire"
  },
  {
    key: "HR",
    label: "Croatia"
  },
  {
    key: "CU",
    label: "Cuba"
  },
  {
    key: "CW",
    label: "Curaçao"
  },
  {
    key: "CY",
    label: "Cyprus"
  },
  {
    key: "CZ",
    label: "Czechia"
  },
  {
    key: "DK",
    label: "Denmark"
  },
  {
    key: "DJ",
    label: "Djibouti"
  },
  {
    key: "DM",
    label: "Dominica"
  },
  {
    key: "DO",
    label: "Dominican Republic"
  },
  {
    key: "EC",
    label: "Ecuador"
  },
  {
    key: "EG",
    label: "Egypt"
  },
  {
    key: "SV",
    label: "El Salvador"
  },
  {
    key: "GQ",
    label: "Equatorial Guinea"
  },
  {
    key: "ER",
    label: "Eritrea"
  },
  {
    key: "EE",
    label: "Estonia"
  },
  {
    key: "ET",
    label: "Ethiopia"
  },
  {
    key: "FK",
    label: "Falkland Islands  [Malvinas]"
  },
  {
    key: "FO",
    label: "Faroe Islands"
  },
  {
    key: "FJ",
    label: "Fiji"
  },
  {
    key: "FI",
    label: "Finland"
  },
  {
    key: "FR",
    label: "France"
  },
  {
    key: "GF",
    label: "French Guiana"
  },
  {
    key: "PF",
    label: "French Polynesia"
  },
  {
    key: "TF",
    label: "French Southern Territories"
  },
  {
    key: "GA",
    label: "Gabon"
  },
  {
    key: "GM",
    label: "Gambia"
  },
  {
    key: "GE",
    label: "Georgia"
  },
  {
    key: "DE",
    label: "Germany"
  },
  {
    key: "GH",
    label: "Ghana"
  },
  {
    key: "GI",
    label: "Gibraltar"
  },
  {
    key: "GR",
    label: "Greece"
  },
  {
    key: "GL",
    label: "Greenland"
  },
  {
    key: "GD",
    label: "Grenada"
  },
  {
    key: "GP",
    label: "Guadeloupe"
  },
  {
    key: "GU",
    label: "Guam"
  },
  {
    key: "GT",
    label: "Guatemala"
  },
  {
    key: "GG",
    label: "Guernsey"
  },
  {
    key: "GN",
    label: "Guinea"
  },
  {
    key: "GW",
    label: "Guinea-Bissau"
  },
  {
    key: "GY",
    label: "Guyana"
  },
  {
    key: "HT",
    label: "Haiti"
  },
  {
    key: "HM",
    label: "Heard Island and McDonald Islands"
  },
  {
    key: "VA",
    label: "Holy See"
  },
  {
    key: "HN",
    label: "Honduras"
  },
  {
    key: "HK",
    label: "Hong Kong"
  },
  {
    key: "HU",
    label: "Hungary"
  },
  {
    key: "IS",
    label: "Iceland"
  },
  {
    key: "IN",
    label: "India"
  },
  {
    key: "ID",
    label: "Indonesia"
  },
  {
    key: "IR",
    label: "Iran"
  },
  {
    key: "IQ",
    label: "Iraq"
  },
  {
    key: "IE",
    label: "Ireland"
  },
  {
    key: "IM",
    label: "Isle of Man"
  },
  {
    key: "IL",
    label: "Israel"
  },
  {
    key: "IT",
    label: "Italy"
  },
  {
    key: "JM",
    label: "Jamaica"
  },
  {
    key: "JP",
    label: "Japan"
  },
  {
    key: "JE",
    label: "Jersey"
  },
  {
    key: "JO",
    label: "Jordan"
  },
  {
    key: "KZ",
    label: "Kazakhstan"
  },
  {
    key: "KE",
    label: "Kenya"
  },
  {
    key: "KI",
    label: "Kiribati"
  },
  {
    key: "KW",
    label: "Kuwait"
  },
  {
    key: "KG",
    label: "Kyrgyzstan"
  },
  {
    key: "LA",
    label: "Laos"
  },
  {
    key: "LV",
    label: "Latvia"
  },
  {
    key: "LB",
    label: "Lebanon"
  },
  {
    key: "LS",
    label: "Lesotho"
  },
  {
    key: "LR",
    label: "Liberia"
  },
  {
    key: "LY",
    label: "Libya"
  },
  {
    key: "LI",
    label: "Liechtenstein"
  },
  {
    key: "LT",
    label: "Lithuania"
  },
  {
    key: "LU",
    label: "Luxembourg"
  },
  {
    key: "MO",
    label: "Macao"
  },
  {
    key: "MK",
    label: "Macedonia"
  },
  {
    key: "MG",
    label: "Madagascar"
  },
  {
    key: "MW",
    label: "Malawi"
  },
  {
    key: "MY",
    label: "Malaysia"
  },
  {
    key: "MV",
    label: "Maldives"
  },
  {
    key: "ML",
    label: "Mali"
  },
  {
    key: "MT",
    label: "Malta"
  },
  {
    key: "MH",
    label: "Marshall Islands"
  },
  {
    key: "MQ",
    label: "Martinique"
  },
  {
    key: "MR",
    label: "Mauritania"
  },
  {
    key: "MU",
    label: "Mauritius"
  },
  {
    key: "YT",
    label: "Mayotte"
  },
  {
    key: "MX",
    label: "Mexico"
  },
  {
    key: "FM",
    label: "Micronesia (Federated States of)"
  },
  {
    key: "MD",
    label: "Moldova"
  },
  {
    key: "MC",
    label: "Monaco"
  },
  {
    key: "MN",
    label: "Mongolia"
  },
  {
    key: "ME",
    label: "Montenegro"
  },
  {
    key: "MS",
    label: "Montserrat"
  },
  {
    key: "MA",
    label: "Morocco"
  },
  {
    key: "MZ",
    label: "Mozambique"
  },
  {
    key: "MM",
    label: "Myanmar"
  },
  {
    key: "NA",
    label: "Namibia"
  },
  {
    key: "NR",
    label: "Nauru"
  },
  {
    key: "NP",
    label: "Nepal"
  },
  {
    key: "NL",
    label: "Netherlands"
  },
  {
    key: "NC",
    label: "New Caledonia"
  },
  {
    key: "NZ",
    label: "New Zealand"
  },
  {
    key: "NI",
    label: "Nicaragua"
  },
  {
    key: "NE",
    label: "Niger"
  },
  {
    key: "NG",
    label: "Nigeria"
  },
  {
    key: "NU",
    label: "Niue"
  },
  {
    key: "NF",
    label: "Norfolk Island"
  },
  {
    key: "KP",
    label: "North Korea"
  },
  {
    key: "MP",
    label: "Northern Mariana Islands"
  },
  {
    key: "NO",
    label: "Norway"
  },
  {
    key: "OM",
    label: "Oman"
  },
  {
    key: "PK",
    label: "Pakistan"
  },
  {
    key: "PW",
    label: "Palau"
  },
  {
    key: "PS",
    label: "Palestine, State of"
  },
  {
    key: "PA",
    label: "Panama"
  },
  {
    key: "PG",
    label: "Papua New Guinea"
  },
  {
    key: "PY",
    label: "Paraguay"
  },
  {
    key: "PE",
    label: "Peru"
  },
  {
    key: "PH",
    label: "Philippines"
  },
  {
    key: "PN",
    label: "Pitcairn"
  },
  {
    key: "PL",
    label: "Poland"
  },
  {
    key: "PT",
    label: "Portugal"
  },
  {
    key: "PR",
    label: "Puerto Rico"
  },
  {
    key: "QA",
    label: "Qatar"
  },
  {
    key: "RE",
    label: "Réunion"
  },
  {
    key: "RO",
    label: "Romania"
  },
  {
    key: "RU",
    label: "Russia"
  },
  {
    key: "RW",
    label: "Rwanda"
  },
  {
    key: "BL",
    label: "Saint Barthélemy"
  },
  {
    key: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    key: "KN",
    label: "Saint Kitts and Nevis"
  },
  {
    key: "LC",
    label: "Saint Lucia"
  },
  {
    key: "MF",
    label: "Saint Martin (French part)"
  },
  {
    key: "PM",
    label: "Saint Pierre and Miquelon"
  },
  {
    key: "VC",
    label: "Saint Vincent and the Grenadines"
  },
  {
    key: "WS",
    label: "Samoa"
  },
  {
    key: "SM",
    label: "San Marino"
  },
  {
    key: "ST",
    label: "Sao Tome and Principe"
  },
  {
    key: "SA",
    label: "Saudi Arabia"
  },
  {
    key: "SN",
    label: "Senegal"
  },
  {
    key: "RS",
    label: "Serbia"
  },
  {
    key: "SC",
    label: "Seychelles"
  },
  {
    key: "SL",
    label: "Sierra Leone"
  },
  {
    key: "SG",
    label: "Singapore"
  },
  {
    key: "SX",
    label: "Sint Maarten (Dutch part)"
  },
  {
    key: "SK",
    label: "Slovakia"
  },
  {
    key: "SI",
    label: "Slovenia"
  },
  {
    key: "SB",
    label: "Solomon Islands"
  },
  {
    key: "SO",
    label: "Somalia"
  },
  {
    key: "ZA",
    label: "South Africa"
  },
  {
    key: "GS",
    label: "South Georgia and the South Sandwich Islands"
  },
  {
    key: "KR",
    label: "South Korea"
  },
  {
    key: "SS",
    label: "South Sudan"
  },
  {
    key: "ES",
    label: "Spain"
  },
  {
    key: "LK",
    label: "Sri Lanka"
  },
  {
    key: "SD",
    label: "Sudan"
  },
  {
    key: "SR",
    label: "Suriname"
  },
  {
    key: "SJ",
    label: "Svalbard and Jan Mayen"
  },
  {
    key: "SZ",
    label: "Swaziland"
  },
  {
    key: "SE",
    label: "Sweden"
  },
  {
    key: "CH",
    label: "Switzerland"
  },
  {
    key: "SY",
    label: "Syria"
  },
  {
    key: "TW",
    label: "Taiwan"
  },
  {
    key: "TJ",
    label: "Tajikistan"
  },
  {
    key: "TZ",
    label: "Tanzania"
  },
  {
    key: "TH",
    label: "Thailand"
  },
  {
    key: "TL",
    label: "Timor-Leste"
  },
  {
    key: "TG",
    label: "Togo"
  },
  {
    key: "TK",
    label: "Tokelau"
  },
  {
    key: "TO",
    label: "Tonga"
  },
  {
    key: "TT",
    label: "Trinidad and Tobago"
  },
  {
    key: "TN",
    label: "Tunisia"
  },
  {
    key: "TR",
    label: "Turkey"
  },
  {
    key: "TM",
    label: "Turkmenistan"
  },
  {
    key: "TC",
    label: "Turks and Caicos Islands"
  },
  {
    key: "TV",
    label: "Tuvalu"
  },
  {
    key: "UG",
    label: "Uganda"
  },
  {
    key: "UA",
    label: "Ukraine"
  },
  {
    key: "AE",
    label: "United Arab Emirates"
  },
  {
    key: "GB",
    label: "United Kingdom"
  },
  {
    key: "UM",
    label: "United States Minor Outlying Islands"
  },
  {
    key: "US",
    label: "United States of America"
  },
  {
    key: "UY",
    label: "Uruguay"
  },
  {
    key: "UZ",
    label: "Uzbekistan"
  },
  {
    key: "VU",
    label: "Vanuatu"
  },
  {
    key: "VE",
    label: "Venezuela"
  },
  {
    key: "VN",
    label: "Vietnam"
  },
  {
    key: "VG",
    label: "Virgin Islands (British)"
  },
  {
    key: "VI",
    label: "Virgin Islands (U.S.)"
  },
  {
    key: "WF",
    label: "Wallis and Futuna"
  },
  {
    key: "EH",
    label: "Western Sahara"
  },
  {
    key: "YE",
    label: "Yemen"
  },
  {
    key: "ZM",
    label: "Zambia"
  },
  {
    key: "ZW",
    label: "Zimbabwe"
  }
];

export default getCountryOptions;
