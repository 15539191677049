import React, { Component } from "react";

// api
import { contactUs } from "../api/api";

// components
import ContactForm from "./ContactForm";

class ContactScreen extends Component {
  state = {
    isMessageSent: false,
  }

  handleSubmitContactForm = (values, setSubmitting, resetForm) => {
    contactUs(values)
      .then(() => {
        resetForm();
        this.setState({ isMessageSent: true })
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render = () => this.state.isMessageSent
    ? "Your message was sent!"
    : <ContactForm onSubmit={this.handleSubmitContactForm}/>
}

export default ContactScreen;
