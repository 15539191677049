import React from "react";

// components
import Textarea from "../components/Textarea";
import Question from "../components/Question";
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// external libs
import { ErrorMessage } from "formik";

import FormControl from "@material-ui/core/FormControl";

// utils
import getOutcomeOptions from "../utils/getOutcomeOptions";
import getOfferConditionsOptions from "../utils/getOfferConditionsOptions";

export const initialValues3 = {
  waitingGettingDecision: "",
  applicationOutcome: "W",
  offerType: "",
  offerConditions: "",
  offerAccepted: "",
  offerNotAcceptedReason: "",
  offerConditionsMet: ""
};

const style = {
  formControl: {
    width: "100%",
    maxWidth: "500px"
  },
  checkbox: {
    marginLeft: "6px"
  },
  errorMessage: {
    color: "red",
    paddingLeft: "17px",
    marginTop: "10px"
  }
}

const FormPart3 = props => {
  return (
    <div style={{ marginTop: "26px" }}>
      <Question label="How did you feel during the waiting period? What happened when you received the letter/the call telling you whether you had a place or not ?">
        <Textarea
          id="waitingGettingDecision"
          value={props.values.waitingGettingDecision}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What was the outcome of your application?" required>
        <FormControl style={style.formControl}>
          <Select
            value={props.values.applicationOutcome}
            onChange={props.handleChange}
            input={<OutlinedInput name="applicationOutcome" />}
          >
            {getOutcomeOptions().map(option => (
              <option value={option.key} key={option.key}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <ErrorMessage style={style.errorMessage} name="applicationOutcome" component={"div"} />
      </Question>

      {(props.values.applicationOutcome === "OM" ||
        props.values.applicationOutcome === "POF") && (
          <Question label="What type of offer?" required>
            <FormControl style={style.formControl}>
              <Select
                value={props.values.offerType}
                onChange={props.handleChange}
                input={<OutlinedInput name="offerType" />}
              >
                <option value="" />
                <option value="C">Conditional</option>
                <option value="U">Unconditional</option>
              </Select>
            </FormControl>
            <ErrorMessage style={style.errorMessage} name="offerType" component={"div"} />
          </Question>
        )}

      {props.values.offerType === "C" && (
        <Question label="What were the conditions of your offer?">
          <Textarea
            id="offerConditions"
            value={props.values.offerConditions}
            onChange={props.handleChange}
          />
        </Question>
      )}

      {props.values.applicationOutcome === "OM" && (
        <Question label="Did you accept the offer?" required>
          <FormControl style={style.formControl}>
            <Select
              value={props.values.offerAccepted}
              onChange={props.handleChange}
              input={<OutlinedInput name="offerAccepted" />}
            >
              <option value="" />
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Select>
          </FormControl>
          <ErrorMessage style={style.errorMessage} name="offerAccepted" component={"div"} />
        </Question>
      )}

      {props.values.offerAccepted === "false" && (
        <Question label="Why did you not accept the offer?">
          <Textarea
            id="offerNotAcceptedReason"
            value={props.values.offerNotAcceptedReason}
            onChange={props.handleChange}
          />
        </Question>
      )}

      {props.values.offerAccepted === "true" && props.values.offerType === "C" && (
        <Question label="Did you meet the conditions of your offer?" required>
          <FormControl style={style.formControl}>
            <Select
              value={props.values.offerConditionsMet}
              onChange={props.handleChange}
              input={<OutlinedInput name="offerConditionsMet" />}
            >
              {getOfferConditionsOptions().map(option => (
                <option value={option.key} key={option.key}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <ErrorMessage style={style.errorMessage} name="offerConditionsMet" component={"div"} />
        </Question>
      )}
    </div>
  );
};

export default FormPart3;
