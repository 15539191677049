import React from "react";

// material
import Input from "@material-ui/core/Input";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// components
import ExpansibleBlock from "../components/ExpansibleBlock";

// utils
import getYearsOptions from "../utils/getYearsOptions";
import getOutcomeOptions from '../utils/getOutcomeOptions';
import getStatusOptions from '../utils/getStatusOptions';

const styles = {
  select: {
    width: "100%",
    textAlign: "left"
  },
  formControl: {
    width: "100%"
  },
  innerFilter: {
    flexDirection: "column",
    width: "100%"
  }
};

const TableHeaderMobile = props => {
  const outcomeOptions = getOutcomeOptions()
  return (
    <div style={{ width: "100%" }}>
      <ExpansibleBlock title="Show profiles for" noImage={true}>
        <div style={styles.innerFilter}>
          <FormControl style={styles.formControl}>
            {/* <InputLabel htmlFor="universityFilter">University</InputLabel> */}
            <Select
              value={props.universityFilter}
              onChange={props.handleFilterChange("universityFilter")}
              style={styles.select}
              displayEmpty
            >
              <MenuItem value="">All universities</MenuItem>
              {props.availableUniversities.map(option => (
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={styles.formControl}>
            {/* <InputLabel htmlFor="collegeFilter">College</InputLabel> */}
            <Select
              value={props.collegeFilter}
              onChange={props.handleFilterChange("collegeFilter")}
              input={<Input name="collegeFilter" />}
              style={styles.select}
              displayEmpty
            >
              <MenuItem value="">All colleges</MenuItem>
              {props.allColleges.map(option => (
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name} ({option.university.name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={styles.formControl}>
            {/* <InputLabel htmlFor="courseFilter">Course</InputLabel> */}
            <Select
              value={props.courseFilter}
              onChange={props.handleFilterChange("courseFilter")}
              input={<Input name="courseFilter" />}
              style={styles.select}
              displayEmpty
            >
              <MenuItem value="">All courses</MenuItem>
              {props.allCourses.map(option => (
                <MenuItem value={option.uuid} key={option.uuid}>
                  {option.name} ({option.university.name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={styles.formControl}>
            {/* <InputLabel htmlFor="yearFilter">Year</InputLabel> */}
            <Select
              value={props.yearFilter}
              onChange={props.handleFilterChange("yearFilter")}
              input={<Input name="yearFilter" />}
              style={styles.select}
              displayEmpty
            >
              <MenuItem value="">All years applied</MenuItem>
              {props.years && props.years.map(option => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={styles.formControl}>
            {/* <InputLabel htmlFor="outcomeFilter">Outcome</InputLabel> */}
            <Select
              value={props.outcomeFilter}
              onChange={props.handleFilterChange('outcomeFilter')}
              input={<Input name="outcomeFilter" />}
              style={styles.select}
              displayEmpty
            >
              <MenuItem value="">All outcomes</MenuItem>
              {props.outcome && outcomeOptions.map(option => {
                 if (props.outcome.indexOf(option.key) > -1) {
                    return (
                      <MenuItem value={option.key} key={option.key}>
                        {option.label}
                      </MenuItem>
                    )
                 }
              }
              )}
            </Select>
          </FormControl>

          {props.isAdmin && (
            <FormControl style={styles.formControl}>
              {/* <InputLabel htmlFor="statusFilter">Status</InputLabel> */}
              <Select
                value={props.statusFilter}
                onChange={props.handleFilterChange("statusFilter")}
                input={<Input name="statusFilter" />}
                style={styles.select}
                displayEmpty
              >
                <MenuItem value="">All statuses</MenuItem>
                {getStatusOptions().map(option => (
                  <MenuItem value={option.key} key={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </ExpansibleBlock>
    </div>
  );
};

export default TableHeaderMobile;
