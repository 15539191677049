import React from "react";

// components
import Textarea from "../components/Textarea";
import Question from "../components/Question";

export const initialValues2 = {
  preInterviewExams: "",
  writtenWork: "",
  interviewExams: "",
  interviewInGeneral: "",
  questionsAtInterview: "",
  clothesAtInterview: "",
  generalCollegeImpression: "",
  collegeAccommodation: ""
};

const FormPart2 = props => {
  return (
    <div style={{ marginTop: "26px" }}>
      <Question label="What, if any, pre-interview exams did you have to take (LNAT/BMAT/..)?">
        <Textarea
          id="preInterviewExams"
          value={props.values.preInterviewExams}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What, if any, written work did you have to submit prior to the interview?">
        <Textarea
          id="writtenWork"
          value={props.values.writtenWork}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What, if any, exams did you have to take as part of the interview?">
        <Textarea
          id="interviewExams"
          value={props.values.interviewExams}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="How was/were in general your interview(s)? Anything stood out in a good way or bad way, or surprised you?">
        <Textarea
          id="interviewInGeneral"
          value={props.values.interviewInGeneral}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What questions were you asked during your interview(s)?">
        <Textarea
          id="questionsAtInterview"
          value={props.values.questionsAtInterview}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What did you wear to your interview(s), and why?">
        <Textarea
          id="clothesAtInterview"
          value={props.values.clothesAtInterview}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="What was your general impression of the college that you applied to/any others you visited?">
        <Textarea
          id="generalCollegeImpression"
          value={props.values.generalCollegeImpression}
          onChange={props.handleChange}
        />
      </Question>

      <Question label="How about the day-to-day aspects of living in the college. If you stayed in college, how were the accommodations? How about the food?">
        <Textarea
          id="collegeAccommodation"
          value={props.values.collegeAccommodation}
          onChange={props.handleChange}
        />
      </Question>
    </div>
  );
};

export default FormPart2;
