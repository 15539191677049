import React from "react";
import styled from "styled-components";

const HamburgerMenuStyled = styled.div.attrs({
  className: "HamburgeMenu"
})`
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 25px;
  cursor: pointer;
  z-index: 999;
`;

const HamburgerLine = styled.div`
  display: inline-flex;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  z-index: 99;
  transition: transform .2s ease, opacity .2s ease;

  &:nth-of-type(1) {
    top: 22%;
    transform: ${ (props) => props.isOpen && `rotate(45deg)`};
    transform-origin: ${ (props) => props.isOpen && `6px 2px`};
  }
  &:nth-of-type(2) {
    top: 50%;
    opacity: ${ (props) => props.isOpen && `0`};
  }
  &:nth-of-type(3) {
    top: 75%;
    transform: ${ (props) => props.isOpen && `rotate(-45deg)`};
    transform-origin: ${ (props) => props.isOpen && `2px -1px`};
  }
`;

const HamburgerMenu = ({ onClick, isOpen, restProps }) => {
  return (
    <HamburgerMenuStyled isOpen={isOpen} {...restProps} onClick={onClick}>
      <HamburgerLine isOpen={isOpen} />
      <HamburgerLine isOpen={isOpen} />
      <HamburgerLine isOpen={isOpen} />
    </HamburgerMenuStyled>
  );
};

export default HamburgerMenu;
