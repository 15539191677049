import React from "react";
import styled from "styled-components";

// components
import Header from "../components/Header";
// import Footer from "../components/Footer";

const ContentStyled = styled.div.attrs({
  className: "Content"
})`
  max-width: ${ ({ props }) => props.wide ? `100%` : `1000px`};
  margin: auto;
  padding: 0 20px 20px 20px;
  text-align: center;
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const Content = props => {
  return (
    <>
      <Header />
      <ContentStyled props={props} style={{ ...props.style }}>{props.children}</ContentStyled>
      {/* <Footer /> */}
    </>
  );
};

export default Content;
