export const replaceUnsafeCharacters = string => string.replace(/[^a-zA-Z0-9-_]+/g, '-').toLowerCase();
export const findEntity = (where, uuid = null, name = null) => {
    return where.find(v => {
            if ((uuid && uuid === v.uuid) || (name && replaceUnsafeCharacters(name).toLowerCase() === replaceUnsafeCharacters(v.name).toLowerCase())) {
                return true
            } else {
                return false
            }
        }
        ) || {
        uuid: '',
        name: ''
    };
}
export const getFilter = filter => (!filter || filter === 'n') ? '' : filter;
