const images: Images = {
  //oxbridge: require("./oxbridge.png"),
  oxbridge: require("./logo-new-3.svg"),
  cambridge: require("./logo_Cambridge.svg"),
  oxford: require("./logo_Oxford.svg"),
  approved: require("./approved.png"),
  rejected: require("./rejected.png"),
  pending: require("./pending.png"),
  profile: require("./profile.svg"),
  Application: require("./application.svg"),
  Detail: require("./detail.svg"),
  Decision: require("./decision.svg"),
  Applicant: require("./applicant.svg"),
  Interview: require("./imppresion.svg"),
  thumb: require("./imppresion.svg"),
  goTo: require("./go-detail.svg"),
  cancel: require("./cancel.svg")
};

export default images;
