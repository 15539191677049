export default {
  _general: {
    signIn: "Sign in",
    signUp: "Sign up"
  },
  about: {
    admin: "Site Administrator",
    adminText1:
      "I will try to answer any queries, comments or suggestions you have about the structure, functions and other details regarding the site itself.",
    adminText2:
      "I will however not be able to answer any queries regarding the actual content itself, i.e.questions about the admissions process.Thank you for understanding.",
    adminText3:
      "You can contact me either using a contact form or directly through my e-mail address.",
    adminText4: "Jan Sramek"
  },
  users: {
    filter: "Filter by",
    name: "Name",
    university: "University",
    college: "College",
    course: "Course",
    yearApplied: "Year applied",
    outcome: "Outcome"
  }
};
