const getStateName = state => {
  switch (state) {
    case "A":
      return "Approved";
    case "P":
      return "Pending";
    case "D":
      return "Rejected";
    default:
      return "";
  }
};

export default getStateName;
