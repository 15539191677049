import React from "react";
import styled from "styled-components";

const GridStyled = styled.div.attrs({
  className: "Grid"
})`
  display: flex;
  flex-direction: ${ (props) => props.column ? `column` : `row`}
  width: ${ (props) => props.width5O ? `50%` : props.width25 ? `25%` : `100%`};
  min-height: ${ (props) => props.fullHeight ? `calc(100vh - 120px)` : `auto`};
  padding: 20px;
  padding-left: ${ (props) => props.spaceLeft ? `100px` : ``};
  padding-right: ${ (props) => props.alignRight ? `100px` : ``};
  justify-content: ${ (props) => props.alignLeft ? `flex-start` : props.alignRight ? `flex-end` : ``};
  ${ (props) => props.spaceTop ? `padding-top: 86px` : ``};

  @media (max-width: 991px) {
    width: 100%;
    padding-top: 20px
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Grid = ({ children, ...restProps }) => {
  return (
    <GridStyled {...restProps}>
      {children}
    </GridStyled>
  );
};

export default Grid;
