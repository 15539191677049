import React from "react";

// material
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

// utils
import getYearsOptions from "../utils/getYearsOptions";
import getOutcomeOptions from '../utils/getOutcomeOptions';
import getStateName from '../utils/getStateName';
import getStatusOptions from '../utils/getStatusOptions';

const cols = [
  {
    property: "university",
    numeric: true,
    disablePadding: true,
    label: "University",
    sortValueGetter: v => v.universityName,
    style: { paddingLeft: "19px" },
  },
  {
    property: "college",
    numeric: true,
    disablePadding: true,
    label: "College",
    sortValueGetter: v => v.collegeName,
  },
  {
    property: "course",
    numeric: true,
    disablePadding: true,
    label: "Course",
    sortValueGetter: v => v.courseName,
  },
  {
    property: "year",
    numeric: true,
    disablePadding: true,
    label: "Year applied",
    sortValueGetter: v => v.year,
  },
  {
    property: "applicationOutcome",
    numeric: true,
    disablePadding: false,
    label: "Outcome",
    sortValueGetter: v => v.applicationOutcome || "",
  },
  {
    property: "profileId",
    numeric: false,
    disablePadding: false,
    label: "P#",
    sortValueGetter: v => v.id,
    // style: { paddingLeft: "31px" }
  }
];

const hiddenCols = [
  {
    property: "status",
    sortValueGetter: v => getStateName(v.state)
  }
]

export function getSortValueGetter(property) {
  const cell = [...cols, ...hiddenCols].find(cell => cell.property === property)
  return (cell && cell.sortValueGetter) || (() => NaN)
}

const TableHeader = props => {
  const outcomeOptions = getOutcomeOptions()
  return (
    <TableHead style={{ fontFamily: "Poppins", color: "#4a4a4a" }}>
      <TableRow style={{ backgroundColor: "#f3f3f3" }}>
        <TableCell align="left" style={{ paddingLeft: "19px" }}>
          <FormControl>
            <NativeSelect
              value={props.universityFilter}
              onChange={props.handleFilterChange("universityFilter")}
              input={<Input name="universityFilter" />}
              style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
            >
              <option value="">All</option>
              {props.availableUniversities.map(option => (
                <option value={option.uuid} key={option.uuid}>
                  {option.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </TableCell>
        <TableCell align="right" padding="none">
          <FormControl>
            <NativeSelect
              value={props.collegeFilter}
              onChange={props.handleFilterChange("collegeFilter")}
              input={<Input name="collegeFilter" />}
              //disabled={!props.universityFilter}
              style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
            >
              <option value="">All</option>
              {props.allColleges.map(option => (
                <option value={option.uuid} key={option.uuid}>
                  {option.name} ({option.university.name})
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </TableCell>
        <TableCell align="right" padding="none">
          <FormControl>
            <NativeSelect
              value={props.courseFilter}
              onChange={props.handleFilterChange("courseFilter")}
              input={<Input name="courseFilter" />}
              //disabled={!props.universityFilter}
              style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
            >
              <option value="">All</option>
              {props.allCourses.map(option => (
                <option value={option.uuid} key={option.uuid}>
                  {option.name} ({option.university.name})
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </TableCell>
        <TableCell align="right" padding="none">
          <FormControl>
            <NativeSelect
              value={props.yearFilter}
              onChange={props.handleFilterChange("yearFilter")}
              input={<Input name="yearFilter" />}
              style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
            >
              <option value="">All</option>
              {props.years && props.years.map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </TableCell>
        <TableCell align="right">
          <FormControl>
            <NativeSelect
              value={props.outcomeFilter}
              onChange={props.handleFilterChange("outcomeFilter")}
              input={<Input name="outcomeFilter" />}
              style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
            >
              <option value="">All</option>
              {props.outcome && outcomeOptions.map(option => {
                 if (props.outcome.indexOf(option.key) > -1) {
                    return (
                      <option value={option.key} key={option.key}>
                        {option.label}
                      </option>
                    )
                 }
              }
              )}
            </NativeSelect>
          </FormControl>
        </TableCell>
        <TableCell/>
        {props.isAdmin && (
          <TableCell align="right">
            <FormControl>
              <NativeSelect
                value={props.statusFilter}
                onChange={props.handleFilterChange("statusFilter")}
                input={<Input name="statusFilter" />}
                style={{ fontSize: "12px", fontWeight: "500", color: "#818181" }}
              >
                <option value="">All</option>
                {getStatusOptions().map(option => (
                  <option value={option.key} key={option.key}>
                    {option.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </TableCell>
        )}
      </TableRow>

      {/* --- */}

      <TableRow>
        {cols.map((cell, idx) => (
          <TableCell
            key={cell.property}
            align={cell.numeric ? "right" : "left"}
            padding={cell.disablePadding ? "none" : "default"}
            sortDirection={props.orderBy === cell.property ? props.order : false}
            style={cell.style}
          >
            <Tooltip
              title="Sort"
              placement={cell.numeric ? "bottom-end" : "bottom-start"}
              enterDelay={300}
            >
              <TableSortLabel
                active={props.orderBy === cell.property}
                direction={props.order}
                onClick={props.createSortHandler(cell.property)}
              >
                {cell.label}
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        ))}
        {props.isAdmin && (
          <TableCell
            align="right"
            padding="default"
            sortDirection={props.orderBy === "status" ? props.order : false}
            style={{ paddingRight: "20px" }}
          >
            <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
              <TableSortLabel
                active={props.orderBy === "status"}
                direction={props.order}
                onClick={props.createSortHandler("status")}
              >
                Status
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
