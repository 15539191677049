import React from "react";

// material
import TextField from "@material-ui/core/TextField";

const styles = {
  textField: {
    margin: "0 0 8px 0",
    width: "100%",
    maxWidth: "500px",
    //border: "solid 1px #c3c3c3",
    borderRadius: "5px"
  }
};

const Textarea = props => {
  return (
    <TextField
      id={props.id}
      multiline
      rowsMax="10"
      rows="6"
      value={props.value}
      onChange={props.onChange}
      margin="normal"
      variant="outlined"
      style={styles.textField}
      placeholder="Type something"
    />
  );
};

export default Textarea;
