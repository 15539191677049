const getYearsOptions = () => {
  const years = [];

  const max = new Date().getFullYear()
  const min = 1996;

  for (var i = min; i <= max; i++) {
    years.push({ label: i, key: i });
  }

  return years;
};

export default getYearsOptions;
