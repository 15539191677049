import React from "react";
import {Helmet} from "react-helmet";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

// material
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
//import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

// components
import Content from "../components/Content";
import TableHeader/*, { getSortValueGetter }*/ from "../components/TableHeader";
import TableHeaderMobile from "../components/TableHeaderMobile";
import NotificationStrip from "../components/NotificationStrip";

// utils
import isMobileView from "../utils/isMobileView";
import getStateName from "../utils/getStateName";
import getOutcomeName from "../utils/getOutcomeName";
import { findEntity, replaceUnsafeCharacters, getFilter } from '../utils';
import { PATHS } from '../App';

// api
import { getProfiles, getUniversities } from "../api/api";
import images from "../images/images";
import AboutBox from "../components/AboutBox";

function desc(a, b) {
  if (b < a) return -1;
  if (b > a) return 1;
  return 0;
}

// function stableSort(array, cmp) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = cmp(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map(el => el[0]);
// }

// function oneByOneSort(array) { // turns [L, L, R, L, L, R, R, L] into [L, R, L, R, L, R, L, L]
//   if (!array.length) return []
//   const FIFO = []
//   const merged = [array[0]]
//   let lastUniversity = array[0].university

//   array.slice(1).forEach(user => {
//     if (user.university !== lastUniversity) {
//       merged.push(user);
//       if (FIFO.length) {
//         user = FIFO.shift()
//         merged.push(user)
//       }
//       lastUniversity = user.university;
//     } else FIFO.push(user)
//   })

//   return [...merged, ...FIFO]
// }

export function getComparator(order, sortValueGetter) {
  return order === "desc"
    ? (a, b) => desc(sortValueGetter(a), sortValueGetter(b))
    : (a, b) => -desc(sortValueGetter(a), sortValueGetter(b));
}

const addUniversity = ({ name, uuid }) => el => ({ ...el, university: { name, uuid } })

let styles

const getStatusImg = status => {
  switch (status) {
    case "A":
      return images.approved;
    case "D":
      return images.rejected;
    case "P":
      return images.pending;
    default:
      break;
  }
};

let isAdmin = false;
const initialLimit = 100; // use 0 in api calls to disable limit (beware: the api result changes form with limit 0)
const moreDesktopLimit = 100;
const moreMobileLimit = 50;

class UsersScreen extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;

    this.state = {
      universityFilter: "",
      collegeFilter: "",
      courseFilter: "",
      outcomeFilter: "",
      yearFilter: "",
      statusFilter: "",

      users: [],
      universities: [],
      availableUniversities: [],
      availableAllColleges: [],
      availableAllCourses: [],
      allColleges: [],
      allCourses: [],

      order: "desc",
      orderBy: "OneByOne", // "profileId"

      // we don't use this pagination (payload), but "load more" button
      // page: 0,
      // rowsPerPage: 15,
      // next
      // previous
      offset: initialLimit,
      count: 0, // total count of profiles fetched
      loading: true, // display "Loading..." text

      aboutRemoved: cookies.get('about_removed') || false,
    };
  }

  componentDidMount() {
    isAdmin = localStorage.getItem('isAdmin') === 'true';

    const state = this.props.history.location.state
    if (state && Object.keys(state).length) {
      this.setState(state)
    } else {
        getUniversities()
        .then(response => this.setState({ availableUniversities: response.data }, ()=> {
            this.getAllAvailableOptions()
        }))
    }
  }

  getAllAvailableOptions = () => {
    let availableAllColleges = [];
    let availableAllCourses = [];
    this.state.availableUniversities.forEach(u => {
      availableAllColleges = availableAllColleges.concat(u.colleges.map(addUniversity(u)))
      availableAllCourses = availableAllCourses.concat(u.courses.map(addUniversity(u)))
    })
    this.setState({ availableAllColleges, availableAllCourses }, ()=> this.loadFiltersFromUrl())
  }

  getAllOptions = () => {
    let allColleges = [];
    let allCourses = [];
    this.state.universities.forEach(u => {
      allColleges = allColleges.concat(u.colleges.map(addUniversity(u)))
      allCourses = allCourses.concat(u.courses.map(addUniversity(u)))
    })
    this.setState({ allColleges, allCourses })
  }

  findCourse = (uuid = null, name = null) => findEntity(this.state.availableAllCourses, uuid, name)
  findCollege = (uuid = null, name = null) => findEntity(this.state.availableAllColleges, uuid, name)
  findUniversity = (uuid = null, name = null) => findEntity(this.state.availableUniversities, uuid, name)

  _loadUsers = options => {
    const {
      statusFilter,
      universityFilter,
      collegeFilter,
      courseFilter,
      outcomeFilter,
      yearFilter
    } = this.state;
    return getProfiles({
      state: statusFilter,
      university: universityFilter,
      college: collegeFilter,
      course: courseFilter,
      outcome: outcomeFilter,
      year: yearFilter,
      ...options
    })
  };

  loadUsers = (limit = initialLimit) => {
    this.setState({ loading: true })
    return this._loadUsers({ limit })
      .then(({ data }) => {
        this.setState({ universities: data.universities||[], years: data.years, outcome: data.outcome }, ()=> this.getAllOptions())
        if (data.results) {
          const { count, /* next, previous, */ results } = data;
          this.setState({ users: results, count, offset: initialLimit, loading: false });
        } else {
          this.setState({ users: data, count: data.length, offset: data.length, loading: false });
        }
      });
  }

  loadMoreUsers = (limit = moreDesktopLimit) => {
    const { offset, users } = this.state;

    this._loadUsers({ limit, offset })
      .then(({ data }) => {
        if (data.results && data.results.length) {
          const { count, /* next, previous, */ results } = data;
          this.setState({ users: [...users, ...results], count, offset: offset + limit });
        }
      });
  }

  handleFilterChange = name => event => {
    if(name == "courseFilter" && event.target.value) {
       const course = findEntity(this.state.availableAllCourses, event.target.value, "")
       this.setState({universityFilter: course.university.uuid})
    }
    if(name == "collegeFilter" && event.target.value) {
       const college = findEntity(this.state.availableAllColleges, event.target.value, "")
       this.setState({universityFilter: college.university.uuid})
    }
    this.setState({ [name]: event.target.value})
    if (name === "universityFilter") this.setState({ collegeFilter: "", courseFilter: "" })
    this.setState({}, () => {
      // schedule these calls after state is changed
      this.loadUsers();
      this.saveFiltersToUrl()
    })
  };

  saveFiltersToUrl() {
    const {
      universityFilter,
      collegeFilter,
      courseFilter,
      outcomeFilter,
      yearFilter,
      statusFilter,
    } = this.state

    let universityFilterValue = replaceUnsafeCharacters(this.findUniversity(universityFilter).name)
    let collegeFilterValue = replaceUnsafeCharacters(this.findCollege(collegeFilter).name)
    let courseFilterValue = replaceUnsafeCharacters(this.findCourse(courseFilter).name)
    let outcomeFilterValue = replaceUnsafeCharacters(outcomeFilter)

    let url = `/${!universityFilterValue ? 'n' : universityFilterValue}/${!collegeFilterValue ? 'n' : collegeFilterValue}/${!courseFilterValue ? 'n' : courseFilterValue}/${!yearFilter ? 'n' : yearFilter}/${!outcomeFilterValue ? 'n' : outcomeFilterValue}/${!statusFilter ? 'n' : statusFilter}`
    let index = url.length - 1;
    let i = url.length-1;
    while ((url[i] === '/' || url[i] === 'n') && i >= 0) {
      i--;
      index--;
    }

    this.props.history.push(url.substring(0, index+1))
  }

  loadFiltersFromUrl = () => {
    const {
      universityFilter,
      collegeFilter,
      courseFilter,
      yearFilter,
      outcomeFilter,
      statusFilter,

    } = this.props.match.params
    this.setState({
      universityFilter: this.findUniversity(null, getFilter(universityFilter)).uuid,
      collegeFilter: this.findCollege(null, getFilter(collegeFilter)).uuid,
      courseFilter: this.findCourse(null, getFilter(courseFilter)).uuid,
      outcomeFilter: getFilter(outcomeFilter).toUpperCase(),
      yearFilter: getFilter(yearFilter),
      statusFilter: isAdmin ? getFilter(statusFilter) : ''
    }, () => {
      this.loadUsers()
      this.saveFiltersToUrl()
    })

  }

  createSortHandler = property => event => this.handleRequestSort(property);

  handleRequestSort = property => {
    let order = "asc";

    if (this.state.orderBy === property && this.state.order === "asc") {
      order = "desc";
    }

    this.setState({ order, orderBy: property });
  };

  handleClick = (event, id) => {
    event.preventDefault();

    this.props.history.replace({ state: this.state })
    this.props.history.push({
      pathname: PATHS.profileId(id),
      state: this.state
    })
  };

  handleClose = () => {
    this.props.cookies.set('about_removed', true, { path: '/' });
    this.setState({ aboutRemoved: true });
  }

  // handleChangePage = (event, page) => {
  //   this.setState({ page });
  // };

  // handleChangeRowsPerPage = event => {
  //   this.setState({ rowsPerPage: event.target.value });
  // };

  renderMobileTable = () => {
    const {
      users,
      count,
      offset,
      loading
    } = this.state;
    const moreProfilesCount = Math.max(Math.min(moreMobileLimit, count - offset), 0)
    const myProfileId = localStorage.getItem('profileId')

    return (
      <>
        <TableHeaderMobile
          handleFilterChange={this.handleFilterChange}
          isAdmin={isAdmin}
          {...this.state}
          // this is what we need from the state
          // universities
          // allColleges,
          // allCourses,
          // all filters
        />

        {users.map(n => (
          <div style={styles.mobileRowWrapper} key={n.uuid}>
            <a href="/" className="Link Link--noUnderline" onClick={event => this.handleClick(event, n.id)}>
              <div style={{ marginBottom: "10px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                {/* {n.profileId} {isAdmin && getStateName(n.state)} */}
                <div>
                  <strong>{n.profileId === myProfileId ? <u>{n.profileId}</u> : n.profileId}</strong> {isAdmin && <img style={{ marginLeft: "10px" }} alt={n.state} src={getStatusImg(n.state)} />}
                </div>
                <Button
                  style={styles.detailButton}
                >
                  <span style={styles.buttonText}>Detail</span>
                </Button>
              </div>
              <div style={{ display: "table" }}><div style={styles.mobileLabel}>University: </div>{n.universityName}</div>
              <div style={{ display: "table" }}><div style={styles.mobileLabel}>College: </div>{n.collegeName}</div>
              <div style={{ display: "table" }}><div style={styles.mobileLabel}>Course: </div>{n.courseName}</div>
              <div style={{ display: "table" }}><div style={styles.mobileLabel}>Year applied: </div>{n.year}</div>
              <div style={{ display: "table" }}><div style={styles.mobileLabel}>Outcome: </div>{getOutcomeName(n.applicationOutcome)}</div>
            </a>
          </div>
        ))}
        <div style={{ textAlign: "center", width: "100%" }}>
          {
            count
              ? moreProfilesCount
                ? <Button
                    style={styles.loadMoreButton}
                    onClick={() => this.loadMoreUsers(moreMobileLimit)}
                    title={`Displaying ${Math.min(count, offset)} of ${count} results`}
                  >
                    <span style={styles.loadMoreButtonText}>Load {moreProfilesCount} more</span>
                  </Button>
                : <span style={styles.loadNoMoreText}>That's it, folks! There are no more profiles.</span>
              : loading
                ? <span style={styles.loadNoMoreText}>Loading profiles...</span>
                : <span style={styles.loadNoMoreText}>Unfortunately, we don't have any profiles that match the criteria you selected.</span>
          }
        </div>

      </>
    );
  };

  renderDesktopTable = () => {
    const { /* rowsPerPage, page, */ count, offset, loading, users } = this.state;
    const moreProfilesCount = Math.max(Math.min(moreMobileLimit, count - offset), 0)
    const myProfileId = localStorage.getItem('profileId')

    return (
      <>
        <Table>
          <TableHeader
            handleFilterChange={this.handleFilterChange}
            createSortHandler={this.createSortHandler}
            isAdmin={isAdmin}
            {...this.state}
            // this is what we need from the state
            // order
            // orderBy
            // universities
            // allColleges,
            // allCourses,
            // all filters
          />
          <TableBody>
            {
              users
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n.id)}
                    tabIndex={-1}
                    key={n.uuid}
                    style={styles.tableRow}
                  >
                    <TableCell
                      style={{ paddingLeft: "19px" }}
                    >
                      {n.universityName}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {n.collegeName}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {n.courseName}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {n.year}
                    </TableCell>
                    <TableCell align="right">
                      {getOutcomeName(n.applicationOutcome)}
                    </TableCell>
                    <TableCell>
                      {
                        n.profileId === myProfileId
                          ? <u>{n.profileId}</u>
                          : n.profileId
                      }
                    </TableCell>
                    {isAdmin && (
                      <TableCell align="right">
                        <div style={styles.statusWrapper}>
                          <span style={styles.status}>
                            {getStateName(n.state)}
                          </span>{" "}
                          <img alt={n.state} src={getStatusImg(n.state)} />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}
        <div style={{ textAlign: 'center', width: '100%' }}>
          {
            count
              ? moreProfilesCount
                ? <Button
                  style={styles.loadMoreButton}
                  onClick={() => this.loadMoreUsers(moreDesktopLimit)}
                  title={`Displaying ${Math.min(count, offset)} of ${count} results`}
                >
                  <span style={styles.loadMoreButtonText}>Load {moreProfilesCount} more</span>
                </Button>
                : <span style={styles.loadNoMoreText}>That's it, folks! There are no more profiles.</span>
              : loading
                ? <span style={styles.loadNoMoreText}>Loading profiles...</span>
                : <span style={styles.loadNoMoreText}>Unfortunately, we don't have any profiles that match the criteria you selected.</span>
          }
        </div>
      </>
    );
  };

  render() {
    styles = {
      loadMoreButton: {
        width: 'auto',
        minWidth: '240px',
        maxWidth: "310px",
        marginBottom: "20px",
        padding: '0 18px',
        ...(isMobileView() ? { width: "100%" } : { marginTop: "15px" }),
        backgroundColor: "#093769",
        borderRadius: "50px",
      },
      loadMoreButtonText: {
        color: "white"
      },
      loadNoMoreText: {
        color: "#093769",
        ...(isMobileView() ? {} : { marginTop: 20 }),
        display: 'inline-block',
      },
      mobileRowWrapper: {
        margin: "0 0 20px",
        border: "1px solid #807c7c",
        padding: "15px",
        textAlign: "left",
        borderRadius: "5px",
        backgroundColor: "#fcfcfc",
        width: "100%",
        position: "relative"
      },
      content: isMobileView() ? {} : {
        maxWidth: "100%",
        padding: "0 0 20px 0",
        flexDirection: "column"
      },
      statusWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      },
      status: {
        paddingRight: "15px"
      },
      tableRow: {
        cursor: "pointer"
      },
      mobileLabel: {
        width: "90px",
        fontSize: "12px",
        color: "#000",
        fontWeight: "bold",
        display: "table-cell"
      },
      detailButton: {
        backgroundColor: "#093769",
        width: "auto",
        borderRadius: "50px",
        height: "25px",
        textTransform: "none",
        padding: 0,
        color: "#fff",
        marginLeft: "auto"
      },
    };

    let cambridgeUuid = ((this.state.universities||[]).find(x => x.name === "Cambridge")||{}).uuid||''
    let oxfordUuid = ((this.state.universities||[]).find(x => x.name === "Oxford")||{}).uuid||''

    const authToken = localStorage.getItem("authToken");

    return (
      <Content style={styles.content}>
        <NotificationStrip />
        {
          this.state.universityFilter === cambridgeUuid ?
            <Helmet>
              <title>Cambridge Admissions - Application Process Information </title>
              <meta name="description" content="Real-world insights and tips for applying to the University of Cambridge from former and current Cambridge students." />
              <meta name="keywords" content="Cambridge University, Oxbridge, Oxbridge Admissions, Cambridge Admissions, Cambridge Applications, Cambridge Deadlines" />
              <meta name="robots" content="index, follow" />
            </Helmet>
          :
          (
            this.state.universityFilter === oxfordUuid ?
            <Helmet>
              <title>Oxford Admissions - Application Process Information</title>
              <meta name="description" content="Real-world insights and tips for applying to Oxford University from former and current Oxford students." />
              <meta name="keywords" content="Oxbridge, Oxford University, Oxbridge Admissions, Oxford Applications, Applying to Oxford 2020, Oxford Deadlines" />
              <meta name="robots" content="index, follow" />
            </Helmet>
            :
            <Helmet>
              <title>Oxbridge Admissions - Application Process Information for Oxford and Cambridge</title>
              <meta name="description" content="Oxbridge Admissions is administered by a group of students who have all gone through the admissions process for either Oxford or Cambridge University; together we hope to dispel any rumours about the two universities, as well as to give general advice on whether you should consider applying, why you should do so, and, if you do, what you should be prepared for." />
              <meta name="keywords" content="Oxbridge, Oxford University, Cambridge University, Oxbridge Admissions, Jan Sramek, Oxbridge Applications" />
              <meta name="robots" content="index, follow" />
            </Helmet>
          )
        }
        { (!authToken && !this.state.aboutRemoved) && <AboutBox handleClose={this.handleClose} />}
        {isMobileView() ? this.renderMobileTable() : this.renderDesktopTable()}
      </Content>
    );
  }
}

export default withCookies(UsersScreen);
