import axios from 'axios/index'
import {setDefaultHeader} from "../api/api";
import config from "../config/appConfig";


const http = axios.create({
    baseURL: config.baseURL,
    timeout: 100000,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    }
})

function responseErrorHandler(error) {
    const {response} = error

    if (response) {
        if (response.status === 401) {
            setDefaultHeader("Authorization");
            localStorage.clear()
            window.location.href = "/";
        }
    }
    return Promise.reject(error)
}

http.interceptors.response.use(
    response => response,
    responseErrorHandler
)

export default http
