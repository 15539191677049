const getOutcomeName = outcome => {
  switch (outcome) {
    case "OM":
      return "Offer made";
    case "POF":
      return "Pooled, offer made";
    case "PR":
      return "Pooled, rejected";
    case "OOM":
      return "Open offer made";
    case "R":
      return "Rejected";
    default:
      return "";
  }
};

export default getOutcomeName;
