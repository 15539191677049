import React from "react";

// material
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

// external libs
import { Formik, ErrorMessage } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

//utils
import isMobileView from "../utils/isMobileView";

// config
import config from "../config/appConfig";

//component
import Error from "../components/Error";

const styles = {
  sendButton: {
    backgroundColor: "#093769",
    width: 240,
    maxWidth: 240,
    borderRadius: "50px",
    height: "45px",
    textTransform: "none"
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
  },
  textInput: {
    margin: "0 0 8px 0",
    width: "100%",
    backgroundColor: "#fff",
  },
  recaptcha: {
    display: "inline-block"
  },
};

const recaptchaRef = React.createRef();

const validate = values => {
  let errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.message) {
    errors.message = "Required";
  }
  if (!values.recaptcha) {
    errors.recaptcha = "Required";
  }
  return errors;
};

const ContactForm = props => {
  return (
    <Formik
      initialValues={{
        email: "",
        name: "",
        topic: "",
        message: "",
        recaptcha: ""
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        props.onSubmit(values, setSubmitting, resetForm);
        recaptchaRef.current.reset();
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        setFieldValue
      }) => (
          <form onSubmit={handleSubmit} style={props.style}>
            <div>
              <TextField
                id="name"
                label="Your name"
                value={values.name}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                style={styles.textInput}
              />
              <Error>
                <ErrorMessage name="name" component={"div"} />
              </Error>
            </div>
            <div>
              <TextField
                id="email"
                label="Your email"
                value={values.email}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                style={styles.textInput}
              />
              <Error>
                <ErrorMessage name="email" component={"div"} />
              </Error>
            </div>
            <div>
              <TextField
                id="topic"
                label="Subject (optional)"
                value={values.topic}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                style={styles.textInput}
              />
            </div>
            <div>
              <TextField
                id="message"
                label="Message"
                multiline
                rowsMax="10"
                rows="6"
                value={values.message}
                onChange={handleChange}
                margin="normal"
                variant="outlined"
                style={styles.textInput}
              />
              <Error>
                <ErrorMessage name="message" component={"div"} />
              </Error>
            </div>
            <div style={{
              width: "100%",
              textAlign: "center"
            }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={config.recaptchaSiteKey}
                onChange={value => setFieldValue("recaptcha", value)}
                style={styles.recaptcha}
                size={isMobileView() ? 'compact' : 'normal'}
              />
            </div>
            <Error>
              <ErrorMessage name="recaptcha" component={"div"} />
            </Error>
            <div style={{
              width: "100%",
              textAlign: "center"
            }}>
              <Button
                type="submit"
                disabled={isSubmitting}
                style={styles.sendButton}
              >
                <span style={styles.buttonText}>Send</span>
              </Button>
            </div>
          </form>
        )}
    </Formik>
  );
};

export default ContactForm;
